import Select from 'react-select';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import ProfilePageStore from 'stores/ProfilePageStore';

import './Select.scss';

const cnMainSelect = block('Select');

interface ISelectProps {
  name: string;
  className?: string;
  options?: any;
  updateFunction: (value: any) => void;
  mobxName: string;
  defaultValue?: string;
}

const CommonSelect = ({ name, className, options, updateFunction, mobxName,defaultValue }: ISelectProps) => {
  const handleChange = (event) => {
    updateFunction(event);
    console.log(event, name);
  };
console.log(defaultValue)
  return (
    <div className={cnMainSelect()}>
      <Select
        defaultValue={defaultValue}
        isMulti
        name={name}
        placeholder={name}
        options={!!options?.length ? options : [{ value: 'Нет данных', label: 'Нет данных' }]}
        className={className ?? 'basic-multi-select'}
        classNamePrefix="select"
        onChange={handleChange}
        value={ProfilePageStore[mobxName as keyof typeof ProfilePageStore]}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'hotpink',
          },
        })}
      />
    </div>
  );
};

export default observer(CommonSelect);
