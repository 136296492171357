import React, {  useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { moneyFormat } from 'shared/utils/utils';

import './AreaChart.scss';

const cnBarChart = block('AreaChart');

interface IAreaChartProps {
  data: any;
  modalState?: boolean;
}

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    const valueCheck = payload[0].value === 1;
    return (
      <div className="custom-tooltip">
        <p className="label">{`Выручка с пациента (топ 30) : ${
          valueCheck ? 'Нет данных' : moneyFormat(payload[0].value)
        }`}</p>
      </div>
    );
  }

  return null;
};

const CustomTooltip2 = ({ payload }: any) => {
  const valueCheck = payload[0]?.value === 1;

    return (
      <div className="custom-tooltip">
        <p className="label">{`Число зарегистрированных случаев : ${
          valueCheck ? 'Нет данных' : payload[0]?.value
        }`}</p>
      </div>
    );
};

const AreaChartWrap = ({ data }: IAreaChartProps) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (containerRef.current) {
          setWidth(containerRef.current.offsetWidth);
          setHeight(containerRef.current.offsetHeight);
        }
      }, [containerRef,MainPageStore.template]);
  return (
    <div className={cnBarChart()}ref={containerRef}>
      <AreaChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis label={MainPageStore.gisOms ? {value:'Число случаев', angle: -90, position: 'insideLeft'} : undefined}/>
        <Tooltip content={MainPageStore.gisOms ? <CustomTooltip2/>:<CustomTooltip />} />
        <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
        {MainPageStore.gisOms && <Legend payload={[{value:'День периода',color:"#8884d8" }]} />}
      </AreaChart>
    </div>
  );
};

export default observer(AreaChartWrap)
