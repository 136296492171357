import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import {  Button, Modal } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import ProfilePageStore from 'stores/ProfilePageStore';

import Input from 'components/Input/Input';
import Select from 'components/Select/Select';

import Img from '../ProfileCard/profile.jpg';

import '../Profile.scss';

const cnProfile = block('Profile');

interface createProfileProps{
    open: boolean;
    setOpen: (boolean)=> void
}


const CreateProfile = ({open,setOpen}:createProfileProps) => {
  const handleClose = () => setOpen(false);
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [family, setFamily] = useState('');
  const [patron, setPatron] = useState('');

  const handleClick = ()=>{
    const groupWrap = ProfilePageStore.group.map((el)=>{
        return el.value
    })
    const TabWrap = ProfilePageStore.tab.map((el)=>{
      return el.value
  })

    ProfilePageStore.createNewUser(username,name,family,patron,email,password,groupWrap.flat(),TabWrap);
    ProfilePageStore.clearUser();
  }

  useEffect(()=>{
    ProfilePageStore.getGroups();
    ProfilePageStore.getTabs();

  },[])

  useEffect(()=>{
    handleClose();
  },[ProfilePageStore.increment])


const allValuse:any =  [];
ProfilePageStore.groups.forEach((el)=>allValuse.push(el.id));


const test = ProfilePageStore.groups.map((el)=>{
  return {label: el.dept, value: el.id}
});


const tabs = ProfilePageStore.tabs.map((el)=>{
  return {label: el.tab, value: el.id}
});

test.unshift({label:'Все отделения', value: allValuse});

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        id="modal-profile"
        aria-describedby="modal-modal-description"
      >
        
          <div className={cnProfile('CreateProfile')}>
            <img width={500} height={500} src={Img} />
            <CloseIcon onClick={() => handleClose()} className="closeIcon" />
            <div className="info">
              <span><Input value={username} placeholder={'Имя пользователя'} onChange={setUsername} /></span>
              <span><Input value={name} placeholder={'Имя'} onChange={setName} /></span>
              <span><Input value={family} placeholder={'Фамилия'} onChange={setFamily} /></span>
              <span><Input value={patron} placeholder={'Отчество'} onChange={setPatron} /></span>
              <span><Input value={email} placeholder={'Email'} onChange={setEmail} /></span>
              <span><Input value={password} placeholder={'Пароль'} onChange={setPassword} /></span>
              <div><Select
                  mobxName={'tab'}
                  options={tabs}
                  name={'Выберите вкладки'}
                  updateFunction={ProfilePageStore.setTab}
                  defaultValue={ProfilePageStore.user.tabs?.map((el)=>el.tab )}
                /></div>
              <div>
                <Select mobxName={'group'} options={test} name={'Выберите отделения'} updateFunction={ProfilePageStore.setGroup} defaultValue='Выберите отделения' />
              </div>
              <Button
                onClick={handleClick}
                sx={{ marginTop: 'auto', width: '50%' }}
                color="success"
                variant="contained"
              >
                <AddIcon /> Создать
              </Button>
              {!!ProfilePageStore.error && <div className='err'>{ProfilePageStore.error}</div>}
            </div>
          </div>
      </Modal>
    </>
  );
};

export default observer(CreateProfile);
