import { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { authStore } from 'stores';
import { AUTH_TOKENS_KEY } from 'stores/authStore';

import Footer from 'components/Footer/Footer';
import { ThemeContext } from 'components/Theme/Theme';
import { RouterPathEnum } from 'shared';

import './RootLayout.scss';

const cnRootLayout = block('RootLayout');

const RootLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthorized = authStore.checkIsAuthorized();
    const checkBrowser = ()=>{
      const is_chrome = navigator.userAgent.indexOf('Chrome') > -1; 
      const is_safari = navigator.userAgent.indexOf("Safari") > -1;

      console.log(is_safari && !is_chrome);

      if (is_safari && !is_chrome) return alert("Пожалуйста, смените браузер на хром или любой другой, кроме сафари. Наше приложение испытывает проблемы с совместимостью на браузерах сафари, мы работаем над устронением этой проблемы, а пока просим использовать другой браузер!!!");
    }
    checkBrowser();

    if (!isAuthorized) {
      authStore.updateToken();
      navigate(RouterPathEnum.Auth);
    }
  }, []);

  useEffect(() => {
    if (!authStore.currentUser) navigate(RouterPathEnum.Auth);
  }, [authStore.currentUser]);

  useEffect(() => {
    if (!localStorage.getItem(AUTH_TOKENS_KEY)) {
      navigate(RouterPathEnum.Auth);
    }
    const fourMinutes = 2592000;

    if (authStore.tokenLoading) {
      authStore.updateToken();
    }

    const interval = setInterval(() => {
      if (localStorage.getItem(AUTH_TOKENS_KEY)) {
        authStore.updateToken();
      }
    }, fourMinutes);
    return () => clearInterval(interval);
  }, [localStorage.getItem(AUTH_TOKENS_KEY), authStore.tokenLoading]);

  const { theme }: any = useContext(ThemeContext);

  return (
    <div className={`${cnRootLayout()} ${theme}`}>
      <Outlet />
      <Footer />
    </div>
  );
};

export default observer(RootLayout);
