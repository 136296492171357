import Select from 'react-select';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import './SelectSearch.scss';

const cnSelectSearch = block('SelectSearch');

interface ISelectProps {
  name: string;
  className?: string;
  options?: any;
  value: string;
  onChange: (value: any) => void;
  /* updateFunction: (value: any) => void;
  mobxName: string; */
}

const SelectSearch = ({ name, className, options, value, onChange/* updateFunction, mobxName */ }: ISelectProps) => {
  
  const handleChange = (event) => {
    onChange(event);
  };


  return (
    <div className={cnSelectSearch()}>
      <Select
        defaultValue={name}
        name={name}
        isClearable
        placeholder={name}
        options={!!options?.length ? options : [{ value: 'Нет данных', label: 'Нет данных' }]}
        className={className ?? 'basic-multi-select'}
        classNamePrefix="select"
        onChange={handleChange}
        value={value}
        theme={(theme) => ({
          ...theme,
          borderRadius: 0,
          colors: {
            ...theme.colors,
            primary25: 'hotpink',
          },
        })}
      />
    </div>
  );
};

export default observer(SelectSearch);
