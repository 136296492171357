import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import Slider from 'components/Slider/Slider';

import CardWrapper from '../components/CardWrapper/CardWrapper';

const Ksg = () => {
  MainPageStore.setKsgPage(true);
  /* console.log(MainPageStore.pageData) */
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', gap: '15px' }}>
        <CardWrapper
          data={MainPageStore.pageData?.summary_cost}
          text={'Суммарная стоимость:'}
          type="Widget"
          money
        />
      </div>
      {MainPageStore.template === 'slider' ? (
        <>
          <Slider
            props={
              <>
                <CardWrapper
                  data={MainPageStore.pageData?.average_ksg_cost?.map((el) => {
                    return { выручка: +el[2], затраты: +el[3], name: el[0] };
                  })}
                  type="Bar"
                  cardtitle={'Средняя стоимость КСГ - МКБ, руб.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.avg_cost_by_ksg?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Средняя стоимость случая (по КСГ, топ 40), тыс. руб.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.avg_cost_by_diagnosis?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Средняя стоимость случая (по диагнозу, топ 40), тыс. руб.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.sum_cost_by_ksg?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Суммарная стоимость случаев (по КСГ, топ 40), тыс. руб.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.sum_cost_by_diagnosis?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Суммарная стоимость случаев (по диагнозу, топ 40), тыс. руб.'}
                />
                {MainPageStore.pageData?.most_expencive_cases && (
                  <CardWrapper data={MainPageStore.pageData?.most_expencive_cases} type={'Table'} />
                )}
              </>
            }
          />
        </>
      ) : (
        <>
          <CardWrapper
            data={MainPageStore.pageData?.average_ksg_cost?.map((el) => {
              return { выручка: +el[2], затраты: +el[3], name: el[0] };
            })}
            type="Bar"
            className="ksg"
            cardtitle={'Средняя стоимость КСГ - МКБ, руб.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.avg_cost_by_ksg?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Средняя стоимость случая (по КСГ, топ 40), тыс. руб.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.avg_cost_by_diagnosis?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Средняя стоимость случая (по диагнозу, топ 40), тыс. руб.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.sum_cost_by_ksg?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Суммарная стоимость случаев (по КСГ, топ 40), тыс. руб.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.sum_cost_by_diagnosis?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Суммарная стоимость случаев (по диагнозу, топ 40), тыс. руб.'}
          />
          {MainPageStore.pageData?.most_expencive_cases && (
            <CardWrapper data={MainPageStore.pageData?.most_expencive_cases} type={'Table'} />
          )}
        </>
      )}
    </>
  );
};

export default observer(Ksg);
