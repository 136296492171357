/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react';
import { animated, useSpring } from 'react-spring';
import { Button, Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector, Tooltip } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

/* import { renderLegend } from 'shared/helpers/chartFuctions'; */
import { COLORS, isMobile, ScreenSize } from 'shared/helpers/helper';
import { truncate } from 'shared/utils/utils';

import SelectSearch from '../SelectSearch/SelectSearch';

import './PieChart2.scss';

const cnPieChart2 = block('PieChart2');

interface IPieChart2Props {
  data: any;
  legend?: boolean;
}

const AnimatedSector = animated(Sector);

const PieChart2 = ({ data }: IPieChart2Props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const containerRef = useRef<HTMLDivElement>(null);

  const [showLegend, setShowLegend] = useState(
    ScreenSize === 'mobile' ? true : isMobile ? false : true,
  );

  const onMouseOver = useCallback((data, index: number) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(undefined);
  }, []);

  const legendHander = () => {
    setShowLegend(!showLegend);
  };

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template,ScreenSize]);

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
      name,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const sx2 =/* ScreenSize === 'pad' ? cx + (outerRadius - (70)) * cos : */
      ScreenSize === 'desktopMiddle'
        ? cx + (outerRadius - (showLegend ? 120 : 140)) * cos
        : cx + (outerRadius - (showLegend ? 145 : 205)) * cos;
    const sy2 = /* ScreenSize === 'pad' ? cy + (outerRadius - (70)) * sin
    : */
      ScreenSize === 'desktopMiddle'
        ? cy + (outerRadius - (showLegend ? 120 : 140)) * sin
        : cy + (outerRadius - (showLegend ? 145 : 205)) * sin;

    const x = sx2 + radius * cos;
    const y = sy2 + radius * sin;

    const sx = cx + (outerRadius + 15) * cos;
    const sy = cy + (outerRadius + 15) * sin;
    const mx = cx + (outerRadius + 35) * cos;
    const my = cy + (outerRadius + 35) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const SpringProps = useSpring({
      to: { outerRadius: outerRadius, cx: sx2, cy: sy2 },
      from: { outerRadius, cx, cy },
      config: { tension: 600, friction: 40 },
    });

    return (
      <g>
        <AnimatedSector
          cx={SpringProps.cx}
          cy={SpringProps.cy}
          innerRadius={innerRadius}
          outerRadius={SpringProps.outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          stroke={'white'}
          className={'labels'}
        />
        <text
          x={x}
          y={y}
          fontSize={'16px'}
          fill="white"
          textAnchor={x > cx ? 'middle' : 'middle'}
          dominantBaseline="central"
          style={{ pointerEvents: 'none' }}
        >
          {`${value.toFixed(2)}`}
        </text>
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={'#787CAC'} fill="none" />
        <line
          x1={ex}
          y1={ey}
          x2={cos <= 0 ? ex - 250 : ex + 250}
          y2={ey}
          style={{ stroke: '#787CAC', strokeWidth: '1' }}
        />
        <text
          x={ex + (cos >= 0 ? 1 : -1) *-12}
          y={ey - 10}
          textAnchor={textAnchor}
          fill="#333"
        >{`${truncate(name, 35)}`}</text>
      </g>
    );
  };

  const containerLegendRef = useRef<any>(null);
  const targetRef = useRef<any>([]);

  const scrollToElement = (i: number) => {
    setActiveIndex(i);

    setTimeout(() => {
      targetRef.current[i].style.opacity = 1;
      targetRef.current[i].style.border = '3px solid white';

      if (containerLegendRef.current && targetRef.current) {
        containerLegendRef.current.scrollTo({
          top: targetRef.current[i].offsetTop,
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  const renderLegend = (props: any) => {
    const { payload } = props;
    const [value, onChange] = useState<any>('');
    const data = payload.filter((entry) => entry.value.includes(value?.value));
    const indexData = payload.indexOf(data[0])
    return (
      <div ref={containerLegendRef} className="legend">
        <SelectSearch
          name={'Поиск'}
          options={payload.map((entry) => {
            return {
              value: entry.value,
              label: entry.value,
            };
          })}
          value={value}
          onChange={onChange}
        />

        {!!data.length && data.map((entry: any, index: any) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid white',
                  borderRadius: '12px',
                  marginBottom: '4px',
                  cursor: 'pointer',
                 
                }}
                key={`item-${index}`}
                onClick={() => (!(ScreenSize === 'mobile') ? scrollToElement(indexData) : undefined)}
                ref={(element) => (targetRef.current![index] = element)}
                className="legendRow"
              >
                <Typography
                  fontSize={14}
                  style={{
                    borderRight: '1px solid gray',
                    width: '80%',
                    textAlign: 'left',
                    padding: '8px',
                  }}
                >
                  {entry.value + ': '}
                </Typography>
                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  {entry.payload.value}
                  <div
                    style={{
                      background: `${entry.color}`,
                      width: '10px',
                      height: '10px',
                      position: 'relative',
                    }}
                  ></div>
                </div>
              </div>
            ))}
          {data.length ==0 && payload.map((entry: any, index: any) => (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  border: '1px solid white',
                  borderRadius: '12px',
                  marginBottom: '4px',
                  cursor: 'pointer',
                }}
                key={`item-${index}`}
                onClick={() => (!(ScreenSize === 'mobile') ? scrollToElement(index) : undefined)}
                ref={(element) => (targetRef.current![index] = element)}
                className="legendRow"
              >
                <Typography
                  
                  style={{
                    borderRight: '1px solid gray',
                    width: '80%',
                    textAlign: 'left',
                    padding: '8px',
                  }}
                >
                  {entry.value + ': '}
                </Typography>
                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  {entry.payload.value.toFixed(2)}
                  <div
                    style={{
                      background: `${entry.color}`,
                      width: '10px',
                      height: '10px',
                      position: 'relative',
                    }}
                  ></div>
                </div>
              </div>
            ))}
      </div>
    );
  };

  return (
    <div
      className={`${cnPieChart2()} ${isMobile && showLegend ? 'mobile' : ''}`}
      ref={containerRef}
    >
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx={
            ScreenSize === 'desktopMiddle'
              ? !showLegend
                ? '50%'
                : '65%'
              : !showLegend
              ? '50%'
              : '67%'
          }
          cy={
            ScreenSize === 'desktopMiddle'
              ? !showLegend
                ? '50%'
                : '95%'
              : !showLegend
              ? '50%'
              : '95%'
          }
          innerRadius={
           /*  ScreenSize === 'pad' ? (!showLegend ? 40 : 40) : */ ScreenSize === 'desktopMiddle' ? (!showLegend ? 80 : 80) : !showLegend ? 140 : 90
          }
          outerRadius={
           /*  ScreenSize === 'pad' ? (!showLegend ? 80 : 60) : */ ScreenSize === 'desktopMiddle' ? (!showLegend ? 150 : 130) : !showLegend ? 220 : 160
          }
          fill="#8884d8"
          paddingAngle={2}
          labelLine={false}
          nameKey="name"
          label={undefined}
          dataKey="value"
          activeShape={renderActiveShape}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          activeIndex={activeIndex}
        >
          {data.map((entry, index: number) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
          })}
        </Pie>

        {showLegend && <Legend align="center" content={renderLegend} />}
      </PieChart>
      {!(ScreenSize === 'mobile') && (
        <Button
          style={{
            position: 'absolute',
            top: '1vw',
            left: '0vw',
            color: 'white',
            textTransform: 'none',
          }}
          size="small"
          variant="contained"
          onClick={legendHander}
        >
          {showLegend ? 'Свернуть легенду' : 'Показать легенду'}
        </Button>
      )}
    </div>
  );
};

export default observer(PieChart2);
