import { useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { Button, ButtonGroup, ButtonProps, Menu, styled, TextField } from '@mui/material';
import { purple } from '@mui/material/colors';
import block from 'bem-cn';
import dayjs from 'dayjs';

import MainPageStore from 'stores/MainPageStore';

import './CalendarWrap.scss';
import 'react-calendar/dist/Calendar.css';

enum Months {
  Январь,
  Февраль,
  Март,
  Апрель,
  Май,
  Июнь,
  Июль,
  Август,
  Сентябрь,
  Октябрь,
  Ноябрь,
  Декабрь,
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  'color': theme.palette.getContrastText(purple[500]),
  'backgroundColor': purple[500],
  '&:hover': {
    backgroundColor: purple[700],
  },
}));

const cnCalendar = block('Calendar');

interface CalendarProps {
  page: string;
}

function CalendarWrap({ page }: CalendarProps) {
  const [value, onChange] = useState<any>(new Date());
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [type, setTYpe] = useState<string>('ДЕНЬ');
  const [inputValue, setInputValue] = useState<string>('Выберите дату');
  const [responseValue, setResponseValue] = useState<any>(value);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setTYpe(event.currentTarget.innerText);
  };

  useEffect(() => {
    switch (type) {
      case 'ГОД':
        if (value.constructor == Array) {
          setInputValue(dayjs(value[0]).format('YYYY'));
          const getYear = (value: Date) => {
            const dte = value[0];
            const y = dte?.getFullYear();
            return [
              dayjs(new Date(y, 0, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, 11, 31)).format('YYYY-MM-DD'),
              dayjs(new Date(y - 1, 0, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y - 1, 11, 31)).format('YYYY-MM-DD'),
            ];
          };
          setResponseValue(getYear(value[0]));
        } else {
          setInputValue(dayjs(value).format('YYYY'));
          const getYear = (value: Date) => {
            const dte = value;
            const y = dte?.getFullYear();
            console.log(value, y);
            return [
              dayjs(new Date(y, 0, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, 11, 31)).format('YYYY-MM-DD'),
              dayjs(new Date(y - 1, 0, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y - 1, 11, 31)).format('YYYY-MM-DD'),
            ];
          };
          setResponseValue(getYear(value));
        }
        break;
      case 'МЕСЯЦ':
        if (value.constructor == Array) {
          setInputValue(
            Months[(dayjs(value[0]).format('M') as unknown as number) - 1] +
              ' ' +
              dayjs(value[0]).format('YYYY'),
          );
          const getDaysOfMonth = (value: Date) => {
            const dte = value;
            const y = dte?.getFullYear();
            const m = dte?.getMonth();
            return [
              dayjs(new Date(y, m, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m + 1, 0)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m - 1, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m, 0)).format('YYYY-MM-DD'),
            ];
          };
          setResponseValue(getDaysOfMonth(value[0]));
        } else {
          setInputValue(
            Months[(dayjs(value).format('M') as unknown as number) - 1] +
              ' ' +
              dayjs(value).format('YYYY'),
          );
          const getDaysOfMonth = (value: Date) => {
            const dte = value;
            const y = dte?.getFullYear();
            const m = dte?.getMonth();
            return [
              dayjs(new Date(y, m, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m + 1, 0)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m - 1, 1)).format('YYYY-MM-DD'),
              dayjs(new Date(y, m, 0)).format('YYYY-MM-DD'),
            ];
          };
          setResponseValue(getDaysOfMonth(value));
        }
        break;
      case 'ПЕРИОД':
        setInputValue(dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD'));
        setResponseValue([
          dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD'),
          dayjs(value[1]?.toDateString() ?? value).format('YYYY-MM-DD'),
        ]);
        break;
      default:
        const getPrevDay = dayjs(value[0] ? (value[0] - 1).toString() : new Date(value - 1)).format(
          'YYYY-MM-DD',
        );
        setInputValue(dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD'));
        setResponseValue([
          dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD'),
          dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD'),
          getPrevDay,
          getPrevDay,
        ]);
    }
  }, [type, value]);

  useEffect(() => {
    if (!(page == 'price')) {
        MainPageStore.setPageData(
          page,
          MainPageStore.departmentSelect?.map((el) => {
            return el.label;
          }),
          responseValue[0],
          responseValue[1],
          responseValue[2],
          responseValue[3],
          MainPageStore.paytypeSelect?.map((el) => {
            return el.label;
          }),
          MainPageStore.bedtypeSelect?.map((el) => {
            return el.label;
          }),
          MainPageStore.roomClassTypeSelect?.map((el) => {
            return el.label;
          }),
          MainPageStore.roomTypeSelect?.map((el) => {
            return el.label;
          }),
          MainPageStore.buildingTypeSelect?.map((el) => {
            return el.label;
          }),
          MainPageStore.filtersInput?.map((el) => {
            return el.label;
          }),
          MainPageStore.mp_profile_filter?.map((el) => {
            return el.label;
          }),
          MainPageStore.smp_ksg_filter?.map((el) => {
            return el.value;
          }),
          MainPageStore.diagnosis_main_filter?.map((el) => {
            return el.value;
          }),
          MainPageStore.fin_source_filter?.map((el) => {
            return el.value;
          }),
          MainPageStore.mp_name_filter?.map((el) => {
            return el.value;
          }),
        );
    } else {
      !!MainPageStore.departmentSelect.length &&
      MainPageStore.setPageData(
        page,
        MainPageStore.departmentSelect?.map((el) => {
          return el.label;
        }),
        responseValue[0],
        responseValue[1],
        responseValue[2],
        responseValue[3],
        MainPageStore.paytypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.bedtypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.roomClassTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.roomTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.buildingTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.filtersInput?.map((el) => {
          return el.label;
        }),
        MainPageStore.mp_profile_filter?.map((el) => {
          return el.label;
        }),
        MainPageStore.smp_ksg_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.diagnosis_main_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.fin_source_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.mp_name_filter?.map((el) => {
          return el.value;
        }),
      );
    }

    MainPageStore.setDates(responseValue);
  }, [responseValue]);

  const handleOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={cnCalendar()}>
      <TextField
        id="outlined-helperText"
        label={
          type === 'ПЕРИОД' ? 'Начальная дата' : type?.split('')[0] + type?.slice(1).toLowerCase()
        }
        defaultValue={dayjs(value[0]?.toDateString() ?? value).format('YYYY-MM-DD')}
        value={inputValue}
        size="small"
        color="secondary"
        focused
        sx={{ marginRight: '1vw' }}
        onClick={handleOpen}
      />
      {type === 'ПЕРИОД' && (
        <TextField
          id="outlined-helperText"
          label="Дата окончания"
          defaultValue={dayjs(value[1]?.toDateString() ?? value).format('YYYY-MM-DD')}
          value={dayjs(value[1]?.toDateString() ?? value).format('YYYY-MM-DD')}
          size="small"
          color="secondary"
          focused
          sx={{ marginRight: '1vw' }}
          onClick={handleOpen}
        />
      )}
      <ButtonGroup>
        <ColorButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          день
        </ColorButton>
        <ColorButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          месяц
        </ColorButton>
        <ColorButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          год
        </ColorButton>
        <ColorButton
          id="basic-button"
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          Период
        </ColorButton>
      </ButtonGroup>
      <Menu
        id="basic-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Calendar
          selectRange={type === 'ПЕРИОД' ?? undefined}
          view={type === 'ГОД' ? 'decade' : type === 'МЕСЯЦ' ? 'year' : undefined}
          onViewChange={onChange}
          maxDetail={type === 'МЕСЯЦ' ? 'year' : type === 'ГОД' ? 'decade' : 'month'}
          onChange={onChange}
          value={value}
        />
      </Menu>
    </div>
  );
}
export default CalendarWrap;
