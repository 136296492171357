import { Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import Heatmap from 'components/Heatmap/Heatmap';
import LineChart from 'components/LineChart/LineChart';
import Table from 'components/Table/Table';
import TableProfile from 'components/TableProfile/TableProfile';

import BarChartVertical from '../../../../components/BarChartVertical/BarChartVertical';

import AreaChart from './AreaChart/AreaChart';
import BarChart from './BarChart/BarChart';
import PieChartWrap from './PieChart/PieChartWrap';
import PieChart2 from './PieChart2/PieChart2';
import PieChart3 from './PieChart3/PieChart3';
import PieChart4 from './PieChart4/PieChart4';
import WidgetCard from './WidgetCard/WidgetCard';

import './Card.scss';

const cnCard = block('Card');

export type CardType = {
  type:
    | 'Pie'
    | 'Area'
    | 'SomeElse'
    | 'Widget'
    | 'Bar'
    | 'Pie2'
    | 'Pie3'
    | 'Line'
    | 'BarVertical'
    | 'Heatmap'
    | 'Table'
    | 'Pie4'
    | 'TableProfile';
};

interface ICardProps {
  data: any;
  type: CardType['type'];
  legend?: boolean;
  modalState?: boolean;
  text?: string;
  cardtitle?: string;
  money?: boolean;
  valueName?: string[];
}

const Card = ({ data, type, legend, modalState, text, cardtitle, money, valueName }: ICardProps) => {
  return (
    <div className={cnCard()}>
      {cardtitle && <Typography fontSize={'large'}>{cardtitle}</Typography>}
      {type === 'Pie' ? (
        data && <PieChartWrap data={data} legend={legend} />
      ) : type === 'Bar' ? (
        data && <BarChart data={data} modalState={modalState} valueName={valueName} />
      ) : type === 'Widget' ? (
        data && <WidgetCard data={data} text={text} money={money} />
      ) : type === 'Pie2' ? (
        data && <PieChart2 data={data} />
      ) : type === 'Area' ? (
        data && <AreaChart data={data} />
      ) : type === 'Pie3' ? (
        data && <PieChart3 data={data} money={money} />
      ) : type === 'Line' ? (
        data && <LineChart data={data} />
      ) : type === 'BarVertical' ? (
        data && <BarChartVertical data={data} valueName={valueName} />
      ) : type === 'Heatmap' ? (
        <Heatmap />
      ) : type === 'Table' ? (
        <Table data={data} />
      ) : type === 'TableProfile' ? (
        <TableProfile data={data} />
      ) : type === 'Pie4' ? (
        data && <PieChart4 data={data} />
      ) : (
        data?.map((element) => {
          return element;
        })
      )}
    </div>
  );
};

export default observer(Card);
