import { useEffect, useRef, useState } from 'react';
import Paper from '@mui/material/Paper';
import { ChartsLegend, ChartsTooltip } from '@mui/x-charts';
import { PiePlot } from '@mui/x-charts/PieChart';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import { moneyFormat, truncate } from 'shared/utils/utils';

import './PieChartWrap.scss';

const cnPieChartWrap = block('PieChartWrap');

interface IPieChartWrapProps {
  data: any;
  legend?: boolean;
}

const CHARTNAMELENGTH = 14;

const PieChartWrap = ({ data,legend }: IPieChartWrapProps) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const sizingProps = {};

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

  return (
    <div className={cnPieChartWrap()} ref={containerRef}>
      <Paper sx={{ width: `${width}px`, height: `${height}px`, background: '#4E2A84' }} elevation={3}>
        <ResponsiveChartContainer
          width={width}
          height={height}
          disableAxisListener={true}
          {...sizingProps}
          series={[
            {
              type: 'pie',
              data: data,
              arcLabel: (el: any) => truncate(el.label, CHARTNAMELENGTH),
              arcLabelRadius: '110%',
              highlightScope: { faded: 'global', highlighted: 'item' },
              faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
              valueFormatter: (el) => `${moneyFormat(el.value)}`,
            },
          ]}
        > 
          {legend && <ChartsLegend direction='column' position={{horizontal:'left',vertical:'top'}} />}
          <PiePlot tooltip={{ trigger: 'item' }} />
          <ChartsTooltip trigger="item" />
          
        </ResponsiveChartContainer>
      </Paper>
    </div>
  );
};

export default observer(PieChartWrap);
