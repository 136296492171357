import { Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import { moneyFormat } from 'shared/utils/utils';

import './WidgetCard.scss';

const cnWidgetCard = block('WidgetCard');

interface IWidgetCardProps {
  data: any;
  text?: string;
  money?: boolean;
}

const WidgetCard = ({ data,text,money }: IWidgetCardProps) => {
  console.log(data[0] =='null',{...data[0]}[0], {...data[0]}[0]== null)
  return (
    <div className={cnWidgetCard('')}>
      <Typography variant='caption'>{text}</Typography>
      <div className={cnWidgetCard('numbers')}>{{...data[0]}[0]== null ? 0 : (money ? moneyFormat(data) : Math.round(parseFloat(data) * 100) / 100)}</div>
    </div>
  );
};

export default observer(WidgetCard);
