import Select from 'react-select';
import { Button } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import './MainSelect.scss';

const cnMainSelect = block('MainSelect');

interface ISelectProps {
  name: string;
  className?: string;
  options?: any;
  page: string;
  updateFunction: (value: any) => void;
  mobxName: string;
}

const MainSelect = ({ name, className, options, page, updateFunction, mobxName }: ISelectProps) => {
  const handleChange = (event) => {
    updateFunction(event);
    console.log(event, name);
  };

  const handleClick = () => {
    MainPageStore.setPageData(
      page,
      MainPageStore.departmentSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.dates[0],
      MainPageStore.dates[1],
      MainPageStore.dates[2],
      MainPageStore.dates[3],
      MainPageStore.paytypeSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.bedtypeSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.roomClassTypeSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.roomTypeSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.buildingTypeSelect?.map((el) => {
        return el.label;
      }),
      MainPageStore.filtersInput?.map((el) => {
        return el.label;
      }),
      MainPageStore.mp_profile_filter?.map((el) => {
        return el.label;
      }),
      MainPageStore.smp_ksg_filter?.map((el) => {
        return el.value;
      }),
      MainPageStore.diagnosis_main_filter?.map((el) => {
        return el.value;
      }),
      MainPageStore.fin_source_filter?.map((el) => {
        return el.value;
      }),
      MainPageStore.mp_name_filter?.map((el) => {
        return el.value;
      }),
    );
  };

  return (
    <div className={cnMainSelect()}>
      <Select
        defaultValue={name}
        isMulti
        name={name}
        placeholder={name}
        options={!!options?.length ? options : [{ value: 'Нет данных', label: 'Нет данных' }]}
        className={className ?? 'basic-multi-select'}
        classNamePrefix="select"
        onChange={handleChange}
        value={MainPageStore[mobxName as keyof typeof MainPageStore]}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: 'hotpink',
          },
        })}
      />
      <Button
        onClick={handleClick}
        sx={{
          color: 'white',
          textTransform: 'none',
          border: '1px solid white',
          marginLeft: 'auto',
        }}
        size="small"
        variant="outlined"
      >
        Показать
      </Button>
    </div>
  );
};

export default observer(MainSelect);
