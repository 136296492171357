import { useContext, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { Backdrop, Box, Fade, Modal } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

/* import MenuWrap from 'components/CommonComponents/MenuWrap/MenuWrap'; */
import { ThemeContext } from 'components/Theme/Theme';

import Card, { CardType } from '../Card/Card';

import './CardWrapper.scss';

const cnCardWrapper = block('CardWrapper');

interface ICardProps {
  data: any;
  type: CardType['type'];
  text?: string;
  cardtitle?: string;
  money?: boolean;
  className?: string;
  valueName?: string[];
}

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  height: '90%',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'visible',
};

const PieChartWrap = ({ data, type, text, cardtitle, money,className, valueName }: ICardProps) => {
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const switchVisible = () => setShow(!show);

  const { theme }: any = useContext(ThemeContext);

  return (
    <div className={`${cnCardWrapper()} ${className ? className : type} ${!show ? cnCardWrapper('hide') : ''}`}>
      <HorizontalRuleIcon fontSize="small" onClick={switchVisible} />
      <ZoomOutMapIcon onClick={handleOpen} sx={{ cursor: 'pointer', position:'absolute',top:'1vw',right:'1vw' }}  />
      {/* <MenuWrap /> */}
      {show && <Card data={data} text={text} type={type} cardtitle={cardtitle} money={money} valueName={valueName} />}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={theme}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <CloseIcon
              onClick={handleClose}
              fontSize="large"
              sx={{
                position: 'absolute',
                top: '2vw',
                right: '2vw',
                fill: 'white',
                zIndex: '9',
                cursor: 'pointer',
              }}
            />
            <Card
              data={data}
              modalState={open}
              type={type}
              legend={true}
              cardtitle={cardtitle}
              money={money}
              valueName={valueName}
            />
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default observer(PieChartWrap);
