import { makeAutoObservable, runInAction } from 'mobx';
import ProfilepageApiService from 'services/api/ProfilepageApiService';

import { IUsers } from 'shared/interfaces/api/ProfilePageInterface';

export const AUTH_TOKENS_KEY = 'authTokens';

class ProfilePageStore {
  public isLoading = false;

  public tokenLoading = true;

  public users: any = [];

  public user: any = [];

  public userId: any = null;

  public group: any[] = [];

  public tab: any[] = [];

  public tabs: any[] = [];

  public groups: any[] = [];

  public error: string = '';

  public increment: number = 0;

  public patchMessage: string = '';

  public selectedRow: number|null = null;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setUser(user: IUsers) {
    this.user = user;
  }

  public setGroup(group: any) {
    this.group = group;
  }

  public setTab(tab: any) {
    this.tab = tab;
  }

  public clearUser() {
    this.user = [];
  }

  public async clearUserId() {
    this.userId = null;
  }

  public setRow(row: any) {
    this.selectedRow = row;
  }

  public async createNewUser(username,name,family,patron,email,password,group,tabs) {
    this.isLoading = true;

    try {
      const data = await ProfilepageApiService.createUser(username,name,patron,family,email,password,group,tabs);

      console.log(data);
      this.isLoading= false;
      this.increment++

    } catch (error:any) {
      console.log(error);
      this.error = error.response?.data?.error || error.response?.data?.username;
    }finally{
      this.isLoading= false;
    }
  }

  public async getGroups(){
    try {
      const data = await ProfilepageApiService.getGroups();

      console.log(data)

      runInAction(() => {
        this.groups = data;
      })
    } catch (error) {
      console.log(error)
    }
  }

  public async getTabs(){
    try {
      const data = await ProfilepageApiService.getTabs();

      console.log(data)

      runInAction(() => {
        this.tabs = data;
      })
    } catch (error) {
      console.log(error)
    }
  }

  public async deleteUser(id){
    try {
      const data = await ProfilepageApiService.deleteUser(id);

      console.log(data);
      this.increment++;
    } catch (error) {
      console.log(error)
    }
  }

  public async patchUser(id,family,name,patron,username,password,email,group,tabs){
    try {
      const data = await ProfilepageApiService.patchUser(id,family,name,patron,username,password,email,group,tabs);

      console.log(data);
      this.increment++
      this.setUser(data)
    } catch (error) {
      console.log(error)
    }
  }

  public async getUserId(id) {
    this.isLoading = true;

    try {
      const user = await ProfilepageApiService.getUSERID(id);

      runInAction(() => {
        this.userId = user;
        this.isLoading = false;
      });
    } catch (error) {
      console.log(error);
      if (error) this.userId = 'Не удалось найти пользователя с таким ID';
    } finally {
      this.isLoading = false;
    }
  }

  public async getUsers() {
    this.isLoading = true;

    try {
      const users = await ProfilepageApiService.getUsers();

      runInAction(() => {
        this.users = users;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public clearDashBoards() {}
}
export default new ProfilePageStore();