/* eslint-disable quote-props */
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import Slider from 'components/Slider/Slider';

import CardWrapper from '../components/CardWrapper/CardWrapper';
import { useEffect } from 'react';

const Beds = () => {
  const NamesDeps: any[] = [];
  const norm_treatment: any[] = [];
  const avg_treatment_length: any[] = [];
  const norm_days: any[] = [];
  const days_per_bed: any[] = [];
  const beds_count: any[] = [];
  const bed_avg_busy: any[] = [];
  const patients_discharged: any[] = [];

  MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    NamesDeps.push(elem.dept);
    norm_treatment.push(elem.norm_treatment);
    avg_treatment_length.push(elem.avg_treatment_length);
    norm_days.push(elem.norm_days);
    days_per_bed.push(elem.days_per_bed);
    beds_count.push(elem.beds_count);
    bed_avg_busy.push(elem.bed_avg_busy);
    patients_discharged.push(elem.patients_discharged);
  });

  const calcSUM1 = MainPageStore.pageData?.beddays_average?.map((elem)=>{
    return elem.beds_count
  })

  const calcSUM2 = MainPageStore.pageData?.beddays_average?.map((elem)=>{
    return elem.bed_avg_busy
  })

  const sumOfNumbers1 = calcSUM1?.flat().reduce((acc, number) => acc + number, 0);

  const sumOfNumbers2 = calcSUM2?.flat().reduce((acc, number) => acc + number, 0);

  const SummaryGraph1 = [
    "Итого",
    sumOfNumbers1, 
    sumOfNumbers2
  ]

  const graphicFirst = MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    return [elem.dept,elem.beds_count,elem.bed_avg_busy]
  });

  graphicFirst?.push(SummaryGraph1)

  const graphicSecond = MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    return[elem.dept,elem.days_per_bed,elem.norm_days]
  });

  const graphicThird = MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    return[elem.dept,elem.norm_days,elem.percent_busy]
  });

  const graphicFour = MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    return[elem.dept,elem.norm_treatment,elem.avg_treatment_length]
  });

  const graphicFive = MainPageStore.pageData?.beddays_average?.map((elem: any) => {
    return[elem.dept,elem.patients_discharged]
  });

  

  useEffect(()=>{
    MainPageStore.secondBar = true;

    return()=>{
      MainPageStore.secondBar = false;
    }
  },[])
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', gap: '15px' }}>
        <CardWrapper
          data={MainPageStore.pageData?.beds_average}
          text={'Занятость коек (для периода - средняя):'}
          type="Widget"
        />
        <CardWrapper data={MainPageStore.pageData?.beds_total} text={'Всего коек:'} type="Widget" />
        {MainPageStore.pageData?.extract_total && (
          <CardWrapper
            data={MainPageStore.pageData?.extract_total}
            text={'Выписанные всего:'}
            type="Widget"
          />
        )}
        {MainPageStore.pageData?.extract_by_dept && (
          <CardWrapper
            data={MainPageStore.pageData?.extract_by_dept}
            text={'Выписанные по отделениям'}
            type="Widget"
          />
        )}
        {MainPageStore.pageData?.dead_total && (
          <CardWrapper
            data={MainPageStore.pageData?.dead_total}
            text={'Умершие всего:'}
            type="Widget"
          />
        )}
        {MainPageStore.pageData?.dead_by_dept && (
          <CardWrapper
            data={MainPageStore.pageData?.dead_by_dept}
            text={'Умершие по отделениям:'}
            type="Widget"
          />
        )}
      </div>
      {MainPageStore.template === 'slider' ? (
        <>
          <Slider
            props={
              <>
                <CardWrapper
                  data={MainPageStore.pageData?.beds_by_bed_type?.map((el) => {
                    return { name: el[1], value: +el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Средняя занятость коек по отделениям'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.beds_by_dept?.map((el) => {
                    return { name: el[1], value: +el[0] };
                  })}
                  type="Pie2"
                  cardtitle="Средняя занятость по типу койки"
                />
              </>
            }
          />
        </>
      ) : (
        <>
          <CardWrapper
            data={MainPageStore.pageData?.beds_by_bed_type?.map((el) => {
              return { name: el[1], value: +el[0] };
            })}
            type="Pie2"
            cardtitle={'Средняя занятость коек по отделениям'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.beds_by_dept?.map((el) => {
              return { name: el[1], value: +el[0] };
            })}
            type="Pie2"
            cardtitle="Средняя занятость по типу койки"
          />
          <CardWrapper
            data={graphicFirst?.map((el) => {
              return { name: el[0], "Число коек в приказе": +el[1], "Среднегодовое количество коек": +el[2] };
            })}
            type={'BarVertical'}
            valueName={["Число коек в приказе", "Среднегодовое количество коек"]}
          />
          <CardWrapper
            data={graphicSecond?.map((el) => {
              return { "name": el[0], 'Работа койки': +el[1], "Норматив работы койки": +el[2] };
            })}
            type={'Bar'}
            valueName={["Работа койки", "Норматив работы койки"]}
          />
          <CardWrapper
            data={graphicThird?.map((el) => {
              // eslint-disable-next-line quote-props
              return { name: el[0], "Норматив работы койки": +el[1], "Процент работы койки": +el[2] };
            })}
            type={'Bar'}
            valueName={["Норматив работы койки", "Процент работы койки"]}
          />
          <CardWrapper
            data={graphicFour?.map((el) => {
              return { name: el[0], "Норматив средних сроков лечения": +el[1], "Средние сроки лечения": +el[2] };
            })}
            type={'BarVertical'}
            valueName={["Норматив средних сроков лечения", "Средние сроки лечения"]}
          />
          <CardWrapper
            data={graphicFive?.map((el) => {
              return { name: el[0], "Количество пациентов, выписавшихся за период": +el[1] };
            })}
            type={'Bar'}
            valueName={["Количество пациентов, выписавшихся за период"]}
          />
        </>
      )}
    </>
  );
};

export default observer(Beds);
