import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import Slider from 'components/Slider/Slider';

import CardWrapper from '../components/CardWrapper/CardWrapper';

const COLORS = [
  '#0088FE',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#8A2BE2',
  '#F4A460',
  '#00FFFF',
  '#F0E68C',
];

const Services = () => {
  console.log(window.screen.width)
  return (
    <>
        {MainPageStore.template === 'slider' ? (
          <><CardWrapper
          data={MainPageStore.pageData?.revenue_total}
          text={'Суммарная выручка:'}
          type="Widget"  money={true}/><Slider
            props={<>
              <CardWrapper
                data={MainPageStore.pageData?.pyment_top_30?.map((el) => {
                  return { name: el[0], цена: el[1] };
                })}
                type="Bar"
                cardtitle={'Оплата за услуги (топ 30), руб.'} />
              <CardWrapper
                data={MainPageStore.pageData?.free_services?.map((el) => {
                  return { name: el[0], value: el[1] };
                })}
                type="Pie3"
                cardtitle={'Оказанные услуги без стоимости (топ 30), шт.'} />
              <CardWrapper
                data={MainPageStore.pageData?.revenue_per_patient?.map((el) => {
                  return { name: el[0], value: el[1] };
                })}
                type="Pie3"
                money
                cardtitle="Выручка с пациента (топ 30), руб." />
              <CardWrapper
                data={MainPageStore.pageData?.revenue_progress?.map((el) => {
                  return { name: el[0], value: el[1] };
                })}
                type="Area"
                cardtitle="Выручка с начала периода, руб." />
              <CardWrapper
                data={MainPageStore.pageData?.services?.map((el: any) => {
                  return { name: el[0], value: el[1] };
                })}
                type="Pie2"
                cardtitle="Оказанные услуги (топ 30), шт." />
              <CardWrapper
                data={MainPageStore.pageData?.revenue_by_dept?.map((el, i) => {
                  return { label: el[0], value: el[1], color: COLORS[i] };
                })}
                type="Pie"
                cardtitle="Выручка по отделениям, руб." />
              <CardWrapper
                data={MainPageStore.pageData?.revenue_by_paytype?.map((el, i) => {
                  return { label: el[0], value: el[1], color: COLORS[i + 1] };
                })}
                type="Pie"
                cardtitle="Выручка по типам оплаты, руб." />
              <CardWrapper
                data={MainPageStore.pageData?.services_amount?.map((el, i) => {
                  return { label: el[0], value: el[1], color: COLORS[i + 2] };
                })}
                type="Pie"
                cardtitle="Число оказанных услуг в отделениях, шт." />
            </>} /></>
        ) : (
          <>
            <CardWrapper
              data={MainPageStore.pageData?.revenue_total}
              text={'Суммарная выручка:'}
              type="Widget"
              money
            />
            <CardWrapper
              data={MainPageStore.pageData?.pyment_top_30?.map((el) => {
                return { name: el[0], цена: el[1] };
              })}
              type="Bar"
              cardtitle={'Оплата за услуги (топ 30), руб.'}
            />
            <CardWrapper
              data={MainPageStore.pageData?.free_services?.map((el) => {
                return { name: el[0], value: el[1] };
              })}
              type="Pie3"
              cardtitle={'Оказанные услуги без стоимости (топ 30), шт.'}
            />
            <CardWrapper
              data={MainPageStore.pageData?.revenue_per_patient?.map((el) => {
                return { name: el[0], value: el[1] };
              })}
              type="Pie3"
              money
              cardtitle="Выручка с пациента (топ 30), руб."
            />
            <CardWrapper
              data={MainPageStore.pageData?.revenue_progress?.map((el) => {
                return { name: el[0], value: el[1] };
              })}
              type="Area"
              cardtitle="Выручка с начала периода, руб."
            />
            <CardWrapper
              data={MainPageStore.pageData?.services?.map((el: any) => {
                return { name: el[0], value: el[1] };
              })}
              type="Pie2"
              cardtitle="Оказанные услуги (топ 30), шт."
            />
            <CardWrapper
              data={MainPageStore.pageData?.revenue_by_dept?.map((el, i) => {
                return { label: el[0], value: el[1], color: COLORS[i] };
              })}
              type="Pie"
              cardtitle="Выручка по отделениям, руб."
            />
            <CardWrapper
              data={MainPageStore.pageData?.revenue_by_paytype?.map((el, i) => {
                return { label: el[0], value: el[1], color: COLORS[i + 1] };
              })}
              type="Pie"
              cardtitle="Выручка по типам оплаты, руб."
            />
            <CardWrapper
              data={MainPageStore.pageData?.services_amount?.map((el, i) => {
                return { label: el[0], value: el[1], color: COLORS[i + 2] };
              })}
              type="Pie"
              cardtitle="Число оказанных услуг в отделениях, шт."
            />
          </>
        )}
      </>
  );
};

export default observer(Services);
