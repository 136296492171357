export enum RouterPathEnum {
  Main = '/home',
  Auth = '/auth',
  None = '/',
  Blocks = '/blocks',
  Profile = '/profile'
}

export enum DragNDropEvents {
  DragEnter = 'dragenter',
  DragLeave = 'dragleave',
  DragOver = 'dragover',
  Drop = 'drop',
}
