import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import ProfilePageStore from 'stores/ProfilePageStore';

import { IUsers } from 'shared/interfaces/api/ProfilePageInterface';

import './TableProfile.scss';

const cnTableProfile = block('TableProfile');

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof IUsers;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    label: '#',
  },
  {
    id: 'user',
    numeric: false,
    disablePadding: false,
    label: 'Пользователь',
  },
  {
    id: 'first_name',
    numeric: false,
    disablePadding: false,
    label: 'Имя',
  },
  {
    id: 'last_name',
    numeric: false,
    disablePadding: false,
    label: 'Фамилия',
  },
  {
    id: 'patron_name',
    numeric: false,
    disablePadding: false,
    label: 'Отчество',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Почта',
  },
  {
    id: 'tabs',
    numeric: false,
    disablePadding: false,
    label: 'Вкладки',
  },
  {
    id: 'group',
    numeric: false,
    disablePadding: false,
    label: 'Группа',
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IUsers) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount?: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IUsers) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={cnTableProfile()}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function ProfileTable({ data }) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof IUsers>('id');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    console.log(data,ProfilePageStore.increment)
    if (ProfilePageStore.userId != null && typeof ProfilePageStore.userId !== 'string') {
      const arrWrap = [ProfilePageStore.userId];
      const rowsdata: any = arrWrap!.map((item: IUsers) => {
        return {
          id: item.id,
          user: item.user,
          first_name: item.first_name,
          last_name: item.last_name,
          patron_name: item.patron_name,
          email: item.email,
          tabs: item.tabs,
          group: item.group,
        };
      });
      return setRows(rowsdata);
    }

    if (typeof ProfilePageStore.userId == 'string') {
      return setRows([]);
    }

    const rowsdata: any = ProfilePageStore.users!.map((item: IUsers) => {
      return {
        id: item.id,
        user: item.user,
        first_name: item.first_name,
        last_name: item.last_name,
        patron_name: item.patron_name,
        email: item.email,
        tabs: item.tabs,
        group: item.group,
      };
    });
    if (!!rows) setRows(rowsdata);
  }, [data, ProfilePageStore.userId]);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof IUsers) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n: any) => n.id);
      if (newSelected) setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number, row) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
    ProfilePageStore.setUser(row);
    ProfilePageStore.setRow(id)
    console.log(id, row);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows!.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      rows
        ? stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage,
          )
        : [],
    [order, orderBy, page, rowsPerPage, rows, data],
  );
  
  return (
    <Box sx={{ width: '100%' }}>
      <Paper className={cnTableProfile()} sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'medium'}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows?.length}
            />
            <TableBody>
              {typeof ProfilePageStore.userId == 'string' && <div>{ProfilePageStore.userId}</div>}
              {visibleRows.map((row: any, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={ProfilePageStore.selectedRow == row.id ? 'Selected' :''}
                    sx={{ cursor: 'pointer'}}
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      /*  padding="none" */
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left">
                      {row.user.id} {row.user.username} {row.user.email}
                    </TableCell>
                    <TableCell align="left">{row.first_name}</TableCell>
                    <TableCell align="left">{row.last_name}</TableCell>
                    <TableCell align="left">{row.patron_name}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell className='group' align="left">
                      {row.tabs.map((el,i) => (row.tabs.length == i+1) ?  el.tab : el.tab+', ')}
                    </TableCell>

                    <TableCell className='group' align="left">
                      {row.group.map((el,i) => i<10 ? el.dept + ', ' : i==10 ?el.dept + '...' : ' ')}
                      Всего Отделений: {row.group.length}
                    </TableCell>
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={!!rows?.length ? rows.length : 5}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export default observer(ProfileTable);
