import { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { moneyFormat } from 'shared/utils/utils';

/* 
import { Loader } from 'components'; */
import './LineChart.scss';

const cnLineChart = block('LineChart');

const CustomTooltip = ({ payload }: any) => {
 
    const valueCheck = payload[0]?.value === 1;
    return (
      <div className="custom-tooltip">
        <p className="label">{`Выручка : ${
          valueCheck ? 'Нет данных' : moneyFormat(payload[0]?.value)
        }`}</p>
      </div>
    );
};

const LineChartWrap = ({data}:any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

  return (
    <div className={cnLineChart()} ref={containerRef}>
      <LineChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip content={<CustomTooltip />}  />
        <Legend payload={[{value:'День периода',color:"#8884d8" }]} />
        <Line type="monotone" dataKey="value" stroke="#8884d8" fill='#8884d8' />
      </LineChart>
    </div>
  );
};

export default observer(LineChartWrap);
