import { useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Bar, CartesianGrid, ComposedChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import {  isPhoneOnly } from 'shared/helpers/helper';
import { moneyFormat, truncate } from 'shared/utils/utils';

import './BarChart.scss';

const cnBarChart = block('BarChart');

interface IBarChartProps {
  data: any;
  modalState?: boolean;
  valueName?: string[];
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (MainPageStore.ksgPage) {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label}`}</p>
          <p className="label">{`Выручка: ${moneyFormat(
            payload[0].payload['выручка'],
          )} и затраты ${moneyFormat(payload[0].payload.затраты)}`}</p>
        </div>
      );
    }
  } else {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${MainPageStore.secondBar ? "" : moneyFormat(payload[0].value)}`}</p>
          {(!payload[1] && MainPageStore.secondBar) &&
            <p className="label">{`${payload[0].value}`}</p>
          }
          {payload[1] && (
            <>
              <p className="label">{`${payload[0].name} : ${MainPageStore.secondBar ? payload[0].value : moneyFormat(payload[0].value)}`}</p>
              <p className="label">{`${payload[1].name} : ${MainPageStore.secondBar ? payload[1].value : moneyFormat(payload[1].value)}`}</p>
            </>
          )}
        </div>
      );
    }
  }
  return null;
};

const renderCustomAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={10} y={10} dy={15} textAnchor="end" fill="#666" transform={/* !MainPageStore.secondBar ? */ "rotate(-65)" /* : '' */}>
        {truncate(payload.value, 30)}
      </text>
    </g>
  );
};

const BarChartWrap = ({ data, valueName }: IBarChartProps) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

  return (
    <div className={cnBarChart()} ref={containerRef}>
      <ComposedChart
        width={width}
        height={height}
        data={data}
        barGap={MainPageStore.ksgPage ? undefined : 25}
        layout="horizontal"
        margin={{ bottom: 25 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          interval={0}
          angle={290}
          dataKey="name"
          tick={!isPhoneOnly && renderCustomAxisTick}
        />
        <YAxis
          padding={MainPageStore.ksgPage ? { bottom: 45 } : undefined}
          tickCount={MainPageStore.ksgPage ? 25 : undefined}
          type="number"
          domain={MainPageStore.ksgPage ? [0, 'dataMax'] : undefined}
        />
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ width: '200px', height: 'auto' }} />
        <Legend verticalAlign={'top'} />
        {/* {MainPageStore.secondBar && (
          <>
            <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
          </>
        )} */}
        <Bar
          dataKey={MainPageStore.ksgPage ? 'выручка' : !MainPageStore.secondBar  ? 'цена' : valueName ? valueName[0] : 'value'}
          stackId={MainPageStore.ksgPage ? 'a' : undefined}
          fill="#8884d8"
        >
          {/* <LabelList dataKey="name" content={Label} /> */}
        </Bar>
        {MainPageStore.secondBar && (
          <Bar dataKey={valueName ? valueName[1] : "value2"} fill="red" stackId={MainPageStore.ksgPage ? 'a' : undefined} />
        )}
        {MainPageStore.ksgPage && (
          <Bar
            dataKey="затраты"
            fill="rgb(255, 127, 14)"
            stackId={MainPageStore.ksgPage ? 'a' : undefined}
          />
        )}
      </ComposedChart>
    </div>
  );
};

export default observer(BarChartWrap);
