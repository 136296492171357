import { makeAutoObservable, runInAction } from 'mobx';

import { IPageData } from 'shared/interfaces';

import { MainPageApiService } from '../services';

export const AUTH_TOKENS_KEY = 'authTokens';

class MainPageStore {
  public isLoading = false;

  public tokenLoading = true;

  public dashBoards: any = {};

  public Tabs: any = [];

  public dates: string[] = [];

  public isAdmin: boolean = false;

  public pageData: IPageData | null = null;

  public template: string = 'mix';

  public user: string = '';

  public ksgPage: boolean = false;

  public gisOms: boolean = false;

  public departmentSelect: any[] = [];

  public paytypeSelect: any[] = [];

  public bedtypeSelect: any[] = [];

  public roomClassTypeSelect: any[] = [];

  public roomTypeSelect: any[] = [];

  public buildingTypeSelect: any[] = [];

  public filters: any[] = [];

  public filtersInput: any[] = [];

  public mp_profile_filter: any[] = [];

  public smp_ksg_filter: any[] = [];

  public diagnosis_main_filter: any[] = [];

  public fin_source_filter: any[] = [];

  public mp_name_filter: any[] = [];

  public secondBar: boolean = false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  public setTemplate(template: string) {
    this.template = template;
  }

  public setKsgPage(value: boolean) {
    this.ksgPage = value;
  }

  public setGisOmsPage(value: boolean) {
    this.gisOms = value;
  }

  updateSelectMPNameFilter(value: any) {
    this.mp_name_filter = value;
  }

  updateSelectFinSourceFilter(value: any) {
    this.fin_source_filter = value;
  }

  updateSelectSMPKSGFilter(value: any) {
    this.smp_ksg_filter = value;
  }

  updateSelectDiagnosisMainFilter(value: any) {
    this.diagnosis_main_filter = value;
  }

  updateSelectMPPRofileFilter(value: any) {
    this.mp_profile_filter = value;
  }

  updateSelectDep(value: any) {
    this.departmentSelect = value;
  }

  updateSelectPayType(value: any) {
    this.paytypeSelect = value;
  }

  updateSelectBedType(value: any) {
    this.bedtypeSelect = value;
  }

  updateSelectRoomClass(value: any) {
    this.roomClassTypeSelect = value;
  }

  updateSelectRoomType(value: any) {
    this.roomTypeSelect = value;
  }

  updateSelectBuildingType(value: any) {
    this.buildingTypeSelect = value;
  }

  updateSelectFilters(value: any) {
    this.filtersInput = value;
  }

  public async getDashBoards() {
    this.isLoading = true;
    const department: { value: any; label: any }[] = [];
    const tablist: { value: any; label: any }[] = [];
    const tabs: any = {
     '1 price': 'Услуги',
      '2 beds': 'Коечный фонд',
      '3 gis_oms': 'ГИС ОМС',
      '4 ksg': 'КСГ',
      '5 drugs': 'Расход препаратов',
      '6 hospitalisation': 'Госпитализация - период',
    };
    try {

      const data = await MainPageApiService.getDashBoards();
      data.dept_filter?.map((dep: any[]) =>
        department.push({
          value: dep[0],
          label: dep[1],
        }),
      );

      data.tab_list?.map((dep: any) =>
        dep != '8 admin' ?
        tablist.push({
          value: dep.split('').slice(2).join(''),
          label: tabs[dep],
        }): this.isAdmin=true,
      );

      runInAction(() => {
        this.dashBoards = department;
        this.Tabs = tablist;
        this.user = data.user;
        this.isLoading = false;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public setDates(dates: string[]) {
    this.dates = dates;
  }

  public async setPageData(
    path: string,
    dept: string[],
    date1: string,
    date2: string,
    date3?: string,
    date4?: string,
    paytype?: string[],
    bedtype?: string[],
    roomclass?: string[],
    roomtype?: string[],
    building?: string[],
    servgroup?: string[],
    mp_profile_filter?: string[],
    smp_ksg_filter?: string[],
    diagnosis_main_filter?: string[],
    fin_source_filter?: string[],
    mp_name_filter?: string[],
  ) {
    this.isLoading = true;
    const department: { value: any; label: any }[] = [];

    try {
      const data = await MainPageApiService.postPage(
        path,
        dept,
        date1,
        date2,
        date3,
        date4,
        paytype,
        bedtype,
        roomclass,
        roomtype,
        building,
        servgroup,
        mp_profile_filter,
        smp_ksg_filter,
        diagnosis_main_filter,
        fin_source_filter,
        mp_name_filter,
      );
      data?.dept_filter?.map((dep: any[]) =>
        department.push({
          value: dep[0],
          label: dep[1],
        }),
      );

      runInAction(() => {
        this.dashBoards = department;
        this.pageData = data;
        this.isLoading = false;

        if (data.servgroup_filter) this.filters = data.servgroup_filter;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  public clearPageInfo() {
    this.pageData = null;
    this.departmentSelect = [];
    this.bedtypeSelect = [];
    this.paytypeSelect = [];
    this.ksgPage = false;
    this.gisOms = false;
  }

  public clearDashBoards() {
    this.dashBoards = {};
  }
}
export default new MainPageStore();
