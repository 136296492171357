import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import CardWrapper from '../components/CardWrapper/CardWrapper';

const Drugs = () => {
  console.log(MainPageStore.pageData)
  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'left', flexWrap: 'wrap', gap: '15px' }}>
        <CardWrapper
          data={MainPageStore.pageData?.total_cost}
          text={'Расходы на препараты:'}
          type="Widget"
          money
        />
      </div>
          <CardWrapper
            data={MainPageStore.pageData?.units_cost?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type={'BarVertical'}
            cardtitle={'Расходы на препараты, руб.'}
          />
    </>
  );
};

export default observer(Drugs);
