/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {  useEffect, useRef, useState } from 'react';
import { animated } from 'react-spring';
import { Button, Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Cell, Legend, Pie, PieChart, Sector, Tooltip } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { COLORS, isMobile, ScreenSize } from 'shared/helpers/helper';
import { truncate } from 'shared/utils/utils';

import './PieChart4.scss';

const cnPieChart4 = block('PieChart4');

interface IPieChart4Props {
  data: any;
  legend?: boolean;
}

const AnimatedSector = animated(Sector);

const PieChart4 = ({ data }: IPieChart4Props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  /* const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined); */
  const containerRef = useRef<HTMLDivElement>(null);
  
  const [showLegend, setShowLegend] = useState(ScreenSize === 'mobile' ? true : isMobile ? false   : true);


  /* const onMouseOver = useCallback((data, index: number) => {
    setActiveIndex(index);
  }, []);
  const onMouseLeave = useCallback(() => {
    setActiveIndex(undefined);
  }, []); */

  const legendHander = () => {
    setShowLegend(!showLegend);
  };

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

  const labels = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      value,
      name,
      active,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 45) * cos;
    const my = cy + (outerRadius + 45) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        <AnimatedSector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          stroke={'white'}
          className={'labels'}
        />
        <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={'#787CAC'} fill="none" />
        <line
          x1={ex}
          y1={ey}
          x2={cos <= 0 ? ex - 250 : ex + 250}
          y2={/* sin > 0.98  ? ey+55 : (sin < -0.98 && cos <0) ?  ey-50: (sin < -0.99 && cos >0) ? ey-55 : */ey}
          style={{ stroke: '#787CAC', strokeWidth: '1' }}
        />
        <text
          x={/* (sin > 0.99  && cos <0)  ? ex + (cos >= 0 ? 1 : -1) * 200 : (sin > 0.98  && cos >0)? ex + (cos >= 0 ? 1 : -1) * 100  : (sin < -0.98 && cos <0) ? ex + (cos >= 0 ? 1 : -1) * 50: (sin < -0.99 && cos >0) ? ex + (cos >= 0 ? 1 : -1)*-35 :  */ex + (cos >= 0 ? 1 : -1) * 5}
          y={/* (sin > 0.99  && cos <0)  ? ey+250: (sin > 0.99  && cos >0)?  ey-320: (sin > 0.98  && cos >0)? ey-330:(sin < -0.98 && cos <0) ?ey-290 : (sin < -0.99 && cos >0) ?  ey+305 : sin < -0.99  ? ey-15: */ ey - 10}
          textAnchor={textAnchor}
          className={/* (sin > 0.99  && cos <0)? 'bot': (sin > 0.98  && cos >0)? 'botright': (sin < -0.98 && cos <0) ?'top' : (sin < -0.99 && cos >0) ? 'topright' :  */undefined}
          fill="#333"
        >{`${truncate(name, 35)}`}</text>
      </g>
    );
  };


  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div
        className='legend'
       
      >
        {payload.map((entry: any, index: any) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px solid white',
              borderRadius: '12px',
              marginBottom: '4px',
              opacity: '1',
            }}
            key={`item-${index}`}
           
            className='legendRow'
          >
            <Typography
              fontSize={14}
              style={{
                width: '80%',
                textAlign: 'left',
                padding: '8px',
                display:'flex',
                alignItems:'center',
                gap:'1vw'
              }}
            >
              {entry.value + ': '} <div
                style={{
                  background: `${entry.color}`,
                  minWidth: '10px',
                  minHeight: '10px',
                  position: 'relative',
                }}
              ></div>
            </Typography>
            <div style={{maxWidth:'4vw',borderLeft:'1px solid gray', padding: '8px',height:'100%',display:'flex',alignItems:'center' }}>
              {entry.payload.value}
            </div>
          </div>
        ))}
      </div>
    );
  };


  return (
    <div className={`${cnPieChart4()} ${isMobile && showLegend ? 'mobile': ''}`} ref={containerRef}>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx={ScreenSize === 'desktopMiddle' ? !showLegend ? '50%' : '65%' : !showLegend ? '50%' : '70%'}
          cy={ ScreenSize === 'desktopMiddle' ? !showLegend ? '50%' : '95%' : !showLegend ? '50%' : '95%'}
          innerRadius={ ScreenSize === 'desktopMiddle' ? !showLegend ? 80 : 80 : !showLegend ? 160 : 120}
          outerRadius={ ScreenSize === 'desktopMiddle' ? !showLegend ? 150 : 130 : !showLegend ? 260 : 180}
          fill="#8884d8"
          paddingAngle={3}
          labelLine={false}
          nameKey="name"
          label={labels}
          dataKey="value"
          /* activeShape={renderActiveShape} */
          /* onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave} */
          /* activeIndex={activeIndex} */
        >
          
          {data.map((entry, index: number) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
          })}
        </Pie>

        {showLegend && (
          <Legend
            align="center"
            content={renderLegend}     
          />
        )}
      </PieChart>
      {!(ScreenSize === 'mobile') &&<Button
        style={{
          position: 'absolute',
          top: '1vw',
          left: '0vw',
          color: 'white',
          textTransform: 'none',
        }}
        size="small"
        variant="contained"
        onClick={legendHander}
      >
        {showLegend ? 'Свернуть легенду' : 'Показать легенду'}
      </Button>}
    </div>
  );
};

export default observer(PieChart4);
