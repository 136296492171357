/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Cell, Legend, Pie, PieChart, Sector, Tooltip } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { COLORS, ScreenSize } from 'shared/helpers/helper';
import { moneyFormat, truncate } from 'shared/utils/utils';

import './PieChart3.scss';

const cnPieChart3 = block('PieChart3');

interface IPieChart3Props {
  data: any;
  money?: boolean;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <>
      <text
        x={x}
        y={y}
        fontSize={'10px'}
        fill="white"
        textAnchor={'middle'}
        dominantBaseline="central"
        style={{ pointerEvents: 'none' }}
      >
        {`${value}`}
      </text>
    </>
  );
};

const CustomTooltip = ({ active, payload, name }: any) => {
  if (active && payload && payload.length) {
    const valueCheck = payload[0].value === 1;
    return (
      <div className="custom-tooltip">
        <p className="label">{`${payload[0].name} - Выручка с пациента (топ 30) : ${
          valueCheck ? 'Нет данных' : moneyFormat(payload[0].value)
        }`}</p>
      </div>
    );
  }

  return null;
};

const PieChart3 = ({ data, money }: IPieChart3Props) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const sorted = data.sort((user1, user2) => user1['value'] > user2['value'] ? 1 : -1);
  const maxValue = sorted[sorted.length - 1]?.value;
  const step = maxValue / 6;
 /*  const values = [0, step, step * 2, step * 3, step * 4, step * 5];
  const outerWrapData = values.map((el) => {
    return { name: el, value: el };
  }); */

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

/*   const renderCustomizedOuterLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    index,
    value,
  }) => {
    const radiusName = innerRadius + (outerRadius - innerRadius) * 25;
    const x = cx + radiusName * Math.cos(-midAngle * RADIAN);
    const x2 = cx + radiusName * Math.cos(-midAngle * RADIAN);
    const y2 = cy + radiusName * Math.sin(-midAngle * RADIAN);

    const valueCheck = value === 1;
    return (
      <>
        <text
          x={x2}
          y={y2}
          fontSize={'10px'}
          fill="white"
          textAnchor={x > cx ? 'start' : 'end'}
          dominantBaseline="central"
        >
          {`${valueCheck ? 'Нет данных' : money? moneyFormat(values[index]) : Math.round(values[index])}`}
        </text>
      </>
    );
  }; */

  const renderLegend = (props: any) => {
    const { payload } = props;

    return (
      <div>
        {payload.map((entry: any, index: any) => (
          (index == 0 || index == payload.length-1) && 
            <div>
              {index == 0 ? 'Самый дешёвый чек: ' + moneyFormat(entry.payload.value) : 'Самый дорогой чек: ' + moneyFormat(entry.payload.value)}
            </div>
        ))}
      </div>
    );
  };

  return (
    <div className={cnPieChart3()} ref={containerRef}>
      <PieChart width={width} height={height}>
        <Pie
          data={!!sorted.length ? sorted : [{ name: 'нет данных', value: 1 }]}
          cx={'50%'}
          cy={'50%'}
          startAngle={-180}
          endAngle={100}
          innerRadius={ScreenSize === 'mobile' ? 25 : 40}
          outerRadius={ScreenSize === 'mobile' ? 90 : 120}
          fill="#8884d8"
          paddingAngle={6}
          nameKey="name"
          label={renderCustomizedLabel}
          dataKey="value"
          labelLine={false}
        >
          {data.map((entry, index: number) => {
            return <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />;
          })}
        </Pie>
       {/*  <Pie
          data={!!data.length ? outerWrapData : [{ name: 'нет данных', value: 1 }]}
          startAngle={-180}
          endAngle={100}
          dataKey="value"
          cx="50%"
          cy="50%"
          innerRadius={ScreenSize === 'mobile' ? 100 : 140}
          outerRadius={ScreenSize === 'mobile' ? 101 : 141}
          fill="#82ca9d"
        /> */}

        <Tooltip content={<CustomTooltip />} wrapperStyle={{ width: '200px', height: 'auto' }} />
        <Legend
            align="center"
            content={renderLegend}  
            verticalAlign={'middle'}   
          />
      </PieChart>
    </div>
  );
};

export default observer(PieChart3);
