import { useContext } from 'react';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { testDataBar, testDataLine } from 'testData/testData';

import { authStore } from 'stores';
import MainPageStore from 'stores/MainPageStore';

import CardWrapper from 'pages/MainPage/components/CardWrapper/CardWrapper';
import { Loader } from 'components';
import Header from 'components/Header/Header';
import { ThemeContext } from 'components/Theme/Theme';

import './Blocks.scss';

const cnBlocks = block('Blocks');

const Blocks = () => {
  const { theme }: any = useContext(ThemeContext);
  MainPageStore.secondBar = true;
  return (
    <>
      <Header />
      <div className={`${cnBlocks.toString()} ${theme}`}>
        <Loader visible={authStore.isLoading} />

        <CardWrapper data={testDataLine} type={'Line'} />
        <CardWrapper data={testDataBar} type={'BarVertical'} />
        <CardWrapper data={testDataBar} type={'Bar'} />
        <CardWrapper data={undefined} type={'Heatmap'} />
        <CardWrapper data={undefined} type={'Table'} />
      </div>
    </>
  );
};

export default observer(Blocks);
