import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import Slider from 'components/Slider/Slider';

import CardWrapper from '../components/CardWrapper/CardWrapper';

const Hospitialization = () => {
  MainPageStore.setGisOmsPage(true);

  return (
    <>
      {MainPageStore.template === 'slider' ? (
        <>
          <Slider
            props={
              <>
                <CardWrapper data={undefined} type={'Heatmap'} cardtitle={'За указанный период'}/>
                <CardWrapper
                  data={MainPageStore.pageData?.ksg_assigned?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Присвоенные КСГ, шт.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.services?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Оказанные услуги (топ 30), шт.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.patients_dynamics?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Area"
                  cardtitle={'Пациенты за период, чел.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.number_of_services?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Pie2"
                  cardtitle={'Число услуг по отделениям, шт'}
                />
              </>
            }
          />
        </>
      ) : (
        <>
          <CardWrapper data={undefined} type={'Heatmap'} cardtitle={'За указанный период'} />
          <CardWrapper
            data={MainPageStore.pageData?.ksg_assigned?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Присвоенные КСГ, шт.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.services?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Оказанные услуги (топ 30), шт.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.patients_dynamics?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Area"
            cardtitle={'Пациенты за период, чел.'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.number_of_services?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Pie2"
            cardtitle={'Число услуг по отделениям, шт'}
          />
        </>
      )}
    </>
  );
};

export default observer(Hospitialization);
