import { GridColDef } from "@mui/x-data-grid";

export const testDataLine = [
  {
    name: 'Page A',
    value: 4000,
  },
  {
    name: 'Page B',
    value: 3000,
  },
  {
    name: 'Page C',
    value: 2000,
  },
  {
    name: 'Page D',
    value: 2780,
  },
  {
    name: 'Page E',
    value: 1890,
  },
  {
    name: 'Page F',
    value: 2390,
  },
  {
    name: 'Page G',
    value: 3490,
  },
];


export const testDataBar = [
   /*  {
        name: "10",
        value: 4000,
        value2: 5000
      },
      {
        name: "20",
        value: 3000,
        value2: 500
      },
      {
        name: "30",
        value: 2000,
        value2: 100
      },
      {
        name: "40",
        value: 2780,
        value2: 200
      },
      {
        name: "50",
        value: 1890,
        value2: 1000
      },
      {
        name: "60",
        value: 2390,
        value2: 2000
      },
      {
        name: "70",
        value: 3490,
        value2: 3000
      },
      {
        name: "80",
        value: 4000,
        value2: 4000
      },
      {
        name: "90",
        value: 3000,
        value2: 5000
      },
      {
        name: "100",
        value: 2000,
        value2: 1000
      }, */
      {
        uv: 1000,    
        
        value: 2780,
        value2: 1000
      },
      {
        name: "Детская",
        value: 1890,
        uv: 2000,
        value2: 1000
      },
      {
        value: 2390,
        uv: 2000,
        value2: 100
      },
      {
        value: 3490,
        uv: 2000,
        value2: 100
      },
      {
        value: 4000,
        uv: 2000,
        value2: 100
      },
      {
        value2: 100,
        name: "Инфекционная",
        uv: 2000,
        value: 3000,
      },
      {
        value: 2000,
        uv: 2000,
        value2: 100
      },
      {
        value: 2780,
        uv: 2000,
        value2: 100
      },
      {
       
        value: 1890,
        uv: 2000,
        value2: 100
      },
      {
        value: 2390,
        name: "Урологическая",
        uv: 2000,
        value2: 100
      },
      {
        value: 3490,
        uv: 2000,
        value2: 100
      },
  ];

 export const columns: GridColDef[] = [
    { field: 'ksg', headerName: 'КСГ',},
    { field: 'mkb', headerName: 'МКБ',},
    { field: 'diagnosisCost', headerName: 'Стоимость случая',},
    { field: 'revenue', headerName: 'Выручка',},
    { field: 'cost', headerName: 'Затраты',},
    { field: 'date', headerName: 'Дата выписки',},
    { field: 'profileMP', headerName: 'Профиль МП',},
  ];
  

export const testTableData = {
    header: ['КСГ','МКБ','Стоимость случая', 'Выручка', 'Затраты', 'Дата выписки', 'Профиль МП'],
    body: [{
        fio:'иванов иван иваныч',
        startDate:'21-03-2022',
        endDate:'21-03-2022',
        sickGroup:'Операции на органе зрения (уровень 5)',
        birthDay:'01/01/1951',
        sex: 'Женский',
        profile:'Офтальмология',
        result:'Выписан'
    },{
        fio:'иванов иван иваныч',
        startDate:'21-03-2022',
        endDate:'21-03-2022',
        sickGroup:'Операции на органе зрения (уровень 5)',
        birthDay:'01/01/1951',
        sex: 'Женский',
        profile:'Офтальмология',
        result:'Выписан'
    },
    {
        fio:'иванов иван иваныч',
        startDate:'21-03-2022',
        endDate:'21-03-2022',
        sickGroup:'Операции на органе зрения (уровень 5)',
        birthDay:'01/01/1951',
        sex: 'Женский',
        profile:'Офтальмология',
        result:'Выписан'
    }
]
}