import { useEffect, useState } from 'react';
import CalendarHeatmap from 'react-calendar-heatmap';
import { Tooltip } from 'react-tooltip';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import 'react-calendar-heatmap/dist/styles.css';
import './Heatmap.scss';

/* const today = new Date(); */
const cnHeatmap = block('Heatmap');

const Heatmap = () => {
  const [randomValues1, setValue] = useState<any>([]);

  useEffect(() => {
    console.log(randomValues1,123);

   const timer = setTimeout(()=>{
      setValue(
        MainPageStore.pageData?.patients_arrived?.map((item) => {
          return {
            date: new Date(item[0]),
            count: item[1],
          };
        }),
      );
      
    },1000)
      
    return ()=>{
      clearTimeout(timer);
    }
    
  }, [MainPageStore.pageData]);

  const getYear =()=>{
    const year =new Date(MainPageStore.dates[0]).getFullYear();

    return [new Date(year,0,1),new Date(year,11,31)]
  }

 

  return (
    <div className={cnHeatmap()}>
      {!!randomValues1?.length ? (
        <CalendarHeatmap
          startDate={getYear()[0]}
          endDate={getYear()[1]}
          values={randomValues1}
          classForValue={(value) => {
            if (!value) {
              return 'color-empty';
            }
            if (value.count == 0) {
              return `color-null`;
            }
            if (value.count <= 50) {
              return `color-50`;
            }
            if (value.count <= 100) {
              return `color-100`;
            }
            if (value.count <= 150) {
              return `color-150`;
            }
            if (value.count <= 200) {
              return `color-200`;
            }
            if (value.count > 200) {
              return `color-200`;
            }
            return `color-github-`;
          }}
          tooltipDataAttrs={(value: {
            date: any;
            count: any;
          }) => {
            if (value.date)
              return {
                'data-tooltip-id': `my-tooltip`,
                'data-tooltip-content': `${new Date(value.date?.toISOString().slice(0, 10)).toLocaleString('ru')} числятся: ${
                  value.count
                }`,
              };
          }}
          showWeekdayLabels={true}
          monthLabels={['Янв', 'Фев', 'Март', 'Апр', 'Май', 'Июнь', 'Июль', 'Авг', 'Сент', 'Окт','Ноя','Дек']}
          weekdayLabels={['Вс','Пн', 'Вт',"Ср", 'Чт','Пт','Сб']}
        />
      ): 'Нет данных'}
      <Tooltip id="my-tooltip" />
      <div className="legend">
        <div className="c0">0</div>
        <div className="c50">50</div>
        <div className="c100">100</div>
        <div className="c150">150</div>
        <div className="c200">200</div>
      </div>
    </div>
  );
};

export default observer(Heatmap);
