import * as React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ContrastIcon from '@mui/icons-material/Contrast';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { authStore } from 'stores';
import MainPageStore from 'stores/MainPageStore';

import { ThemeContext } from 'components/Theme/Theme';
import { RouterPathEnum } from 'shared';

import { ReactComponent as Logo} from '../../Icons/testLogo.svg';


function Header() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  const { theme, toggleTheme }:any = useContext(ThemeContext);
  const navigate = useNavigate();
  
  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const unLogin = () => {
    setAnchorEl(null);
    authStore.clearUserInfo();
  };

  const adminPanelRedirect = () => {
    setAnchorEl(null);
    navigate(RouterPathEnum.Profile);
  };

  React.useEffect(()=>{

  },[MainPageStore.isAdmin])

  return (
    <Box sx={{ flexGrow: 1,}}>
      <AppBar sx={{ borderRadius: '0' }} position="static">
        <Toolbar>
        <IconButton style={{color:'white'}} children={<ContrastIcon  />} className='theme' onClick={()=>toggleTheme()} />
          <Logo className='logo' />
          <Typography className='lebel' variant="h6" component="div" sx={{ flexGrow: 1, marginLeft:'1vw', }}>
            CBI
          </Typography>

          <div>
            Вы вошли как: <span className='user'>{MainPageStore.user}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
             {MainPageStore.isAdmin && <MenuItem onClick={adminPanelRedirect}>Профиль</MenuItem>}
              <MenuItem onClick={unLogin}>Выйти</MenuItem>
            </Menu>
            </span>
          </div>
        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
