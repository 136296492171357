import { useEffect, useState } from 'react';
import Select from 'react-select';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import { Loader } from 'components';
import Header from 'components/Header/Header';

import Beds from './Category/Beds';
import Drugs from './Category/Drugs';
import Filters from './Category/Filters';
import Gis_oms from './Category/Gis_oms';
import Hospitialization from './Category/Hospitialization';
import Ksg from './Category/Ksg';
import Services from './Category/Services';
import CalendarWrap from './components/Calendar/CalendarWrap';
import FlexDivider from './components/FlexDivider/FlexDivider';
import MainSelect from './components/Select/MainSelect';

import './MainPage.scss';

const cnMainPage = block('MainPage');

interface data {
  value: string;
  label: string;
}

const MainPage = () => {

  useEffect(() => {
    MainPageStore.getDashBoards();

    return () => {
      MainPageStore.clearDashBoards();
    };
  }, []);


  const [value, onChange] = useState<data>({
    value: 'price',
    label: 'Услуги',
  });

  useEffect(() => {
    MainPageStore.clearPageInfo();

    if (!(value.value === 'price')) {
      MainPageStore.setPageData(
        value.value,
        MainPageStore.departmentSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.dates[0],
        MainPageStore.dates[1],
        MainPageStore.dates[2],
        MainPageStore.dates[3],
        MainPageStore.paytypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.bedtypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.roomClassTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.roomTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.buildingTypeSelect?.map((el) => {
          return el.label;
        }),
        MainPageStore.mp_profile_filter?.map((el) => {
          return el.label;
        }),
        MainPageStore.smp_ksg_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.diagnosis_main_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.fin_source_filter?.map((el) => {
          return el.value;
        }),
        MainPageStore.mp_name_filter?.map((el) => {
          return el.value;
        }),
      );
    } else {
      MainPageStore.getDashBoards();
    }
  }, [value]);

  return (
    <div className={`${cnMainPage()} `}>
      <Header />
      <div className={cnMainPage('top')}>
        <div>
          <Select
            defaultValue={MainPageStore.Tabs[0]?.label}
            name="Главный селект"
            options={MainPageStore.Tabs}
            className="selectMain"
            classNamePrefix="selectMain"
            placeholder={MainPageStore.Tabs[0]?.label}
            value={value}
            onChange={(e) =>
              e.value !== value.value ? onChange({ value: e.value, label: e.label }) : undefined
            }
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: 'hotpink',
              },
            })}
          />
        </div>
        <CalendarWrap page={value.value} />
      </div>
      <div className={cnMainPage(`dashBoard ${MainPageStore.ksgPage ? '' : 'selects'}`)}>
        {/* (!!MainPageStore.pageData?.dept_filter?.length || !!MainPageStore.dashBoards?.length) && */ (
          <MainSelect
            name={'Отделение'}
            page={value.value}
            options={MainPageStore.dashBoards}
            updateFunction={MainPageStore.updateSelectDep}
            mobxName={'departmentSelect'}
          />
        )}
        <Filters page={value.value} />
      </div>
      <Loader visible={MainPageStore.isLoading} />
      {MainPageStore.pageData && (
        <>
          {value.value != 'drugs' && <div className={cnMainPage('settings')}>
            <FlexDivider />
          </div>}
          <div className={cnMainPage(`dashBoard ${MainPageStore.template}`)}>
            {value.label === 'Услуги' && <Services />}
            {value.label === 'Коечный фонд' && <Beds />}
            {value.label === 'КСГ' && <Ksg />}
            {value.value === 'drugs' && <Drugs />}
            {value.value === 'gis_oms' && <Gis_oms />}
            {value.value === 'hospitalisation' && <Hospitialization />}
          </div>
        </>
      )}
     
    </div>
  );
};

export default observer(MainPage);
