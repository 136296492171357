import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Modal, Typography } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import ProfilePageStore from 'stores/ProfilePageStore';

import Input from 'components/Input/Input';
import Select from 'components/Select/Select';

import Img from './profile.jpg';

import '../Profile.scss';

const cnProfile = block('Profile');

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ProfileCard = () => {
  const [showSpoiler, setShowSpoiler] = useState(false);
  const [showSpoiler2, setShowSpoiler2] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [family, setFamily] = useState('');
  const [patron, setPatron] = useState('');
  const [edit, setEditable] = useState(false);

  const HandleDetele = () => {
    ProfilePageStore.deleteUser(ProfilePageStore.user.id);
    handleClose();
    ProfilePageStore.clearUser();
  };

  const editHandle = () => {
    setEditable(true);
  };

  const saveEdit = () => {
   
    setEditable(false);
    const groupWrap = ProfilePageStore.group.map((el)=>{
        return el.value
    })
    const TabWrap = ProfilePageStore.tab.map((el)=>{
      return el.value
    })
    
    ProfilePageStore.patchUser(
      ProfilePageStore.user.id,
      family,
      name,
      patron,
      username,
      password,
      email, 
      groupWrap.flat(),
      TabWrap,
    );
  }; 

  useEffect(() => {
    
  }, [ProfilePageStore.increment]);

  const CloseIconHandler = () => {
    ProfilePageStore.clearUser();
    CancelEditHandler();
    ProfilePageStore.setRow(null);
  };

  const CancelEditHandler = () => {
    setEditable(false);
    setUsername(ProfilePageStore.user?.user?.username);
    setEmail(ProfilePageStore.user?.email);
    setName(ProfilePageStore.user?.first_name);
    setPassword(ProfilePageStore.user.user?.password);
    setFamily(ProfilePageStore.user?.last_name);
    setPatron(ProfilePageStore.user?.patron_name);
  };

  useEffect(()=>{
    CancelEditHandler()
  },[ProfilePageStore.user.id])

  const allValuse:any =  [];
ProfilePageStore.groups.forEach((el)=>allValuse.push(el.id));


const test = ProfilePageStore.groups.map((el)=>{
  return {label: el.dept, value: el.id}
});

const tabs = ProfilePageStore.tabs.map((el)=>{
  return {label: el.tab, value: el.id}
});

const activeTabs = ProfilePageStore.user.tabs?.map((el)=>{
  return {label: el.tab, value: el.id}
});

const activeGroups = ProfilePageStore.user.group?.map((el)=>{
  return {label: el.dept, value: el.id}
});

test.unshift({label:'Все отделения', value: allValuse});

ProfilePageStore.tab =activeTabs;
ProfilePageStore.group =activeGroups;
  return (
    <>
      {!!ProfilePageStore.user.id && (
        <div className={cnProfile('ProfileCard')}>
          <img width={500} height={500} src={Img} />
          <CloseIcon onClick={CloseIconHandler} className="closeIcon" />
          <div className="info">
            <span><span className='Property'>ID:</span> {ProfilePageStore.user.id}</span>
            {!edit ? (
              <span><span className='Property'>Фамилия:</span> {ProfilePageStore.user.last_name}</span>
            ) : (
              <span>
                <Input
                  value={family}
                  placeholder={'Фамилия'}
                  onChange={setFamily}
                  defaultValue={ProfilePageStore.user.last_name}
                />
              </span>
            )}
            {!edit ? (
              <span><span className='Property'>Имя:</span> {ProfilePageStore.user.first_name}</span>
            ) : (
              <span>
                <Input
                  value={name}
                  placeholder={'Имя'}
                  onChange={setName}
                  defaultValue={ProfilePageStore.user.first_name}
                />
              </span>
            )}
            {!edit ? (
              <span><span className='Property'>Отчество:</span> {ProfilePageStore.user.patron_name}</span>
            ) : (
              <span>
                <Input
                  value={patron}
                  placeholder={'Отчество'}
                  onChange={setPatron}
                  defaultValue={ProfilePageStore.user.patron_name}
                />
              </span>
            )}
            {!edit ? (
              <span><span className='Property'>Имя пользователя:</span> {ProfilePageStore.user.user?.username}</span>
            ) : (
              <span>
                <Input
                  value={username}
                  placeholder={'Имя пользователя'}
                  onChange={setUsername}
                  defaultValue={ProfilePageStore.user.user?.username}
                />
              </span>
            )}
            {!edit ? (
              <span><span className='Property'>Пароль:</span>************</span>
            ) : (
              <span>
                <Input
                  value={password}
                  placeholder={"Пароль"}
                  onChange={setPassword}
                />
              </span>
            )}
            {!edit ? (
              <span><span className='Property'>Почта:</span> {ProfilePageStore.user.email}</span>
            ) : (
              <span>
                <Input
                  value={email}
                  placeholder={'Email'}
                  onChange={setEmail}
                  defaultValue={ProfilePageStore.user.email}
                />
              </span>
            )}
            {!edit ? (
              <div>
                <span className='Property'>Табы</span>{' '}
                <Button variant="contained" onClick={() => setShowSpoiler2(!showSpoiler2)}>
                  {`${!showSpoiler2 ? 'Показать вкладки' : 'Скрыть'}`}
                </Button>
                <div className={`Spoiler ${showSpoiler2 ? 'show' : ''}`}>
                  {ProfilePageStore.user.tabs?.map((el,i) =>  ProfilePageStore.user.tabs.length-1 == i ? el.tab :el.tab + ', ')}
                </div>{' '}
              </div>
            ) : (
              <div>
                <Select
                  mobxName={'tab'}
                  options={tabs}
                  name={'Выберите вкладки'}
                  updateFunction={ProfilePageStore.setTab}
                  defaultValue={activeTabs}
                />
              </div>
            )}
            {!edit ? (
              <div>
                <span className='Property'>Группы доступа</span>{' '}
                <Button variant="contained" onClick={() => setShowSpoiler(!showSpoiler)}>
                  {`${!showSpoiler ? 'Показать группы' : 'Скрыть'}`}
                </Button>
                <div className={`Spoiler ${showSpoiler ? 'show' : ''}`}>
                  {ProfilePageStore.user.group?.map((el,i) =>  ProfilePageStore.user.group.length-1 == i ? el.dept :el.dept + ', ')}
                </div>{' '}
              </div>
            ) : (
              <div>
                <Select
                  mobxName={'group'}
                  options={test}
                  name={'Выберите отделения'}
                  updateFunction={ProfilePageStore.setGroup}
                  defaultValue={ProfilePageStore.user.group.map((el)=>el.dept )}
                />
              </div>
            )}
            <div className="tools">
              {!edit ? (
                <Button onClick={editHandle} color="info" variant="contained">
                  <EditIcon /> Редактировать
                </Button>
              ) : (
                <>
                  <Button onClick={saveEdit} color="success" variant="contained">
                    <EditIcon /> Сохранить
                  </Button>
                  <Button onClick={CancelEditHandler} color="error" variant="outlined">
                   Отмена <CloseIcon />
                  </Button>
                </>
              )}
              {!edit && (
                <Button onClick={handleOpen} color="error" variant="outlined">
                  <DeleteIcon /> Удалить пользователя
                </Button>
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              id="modal-profile"
            >
              <Box sx={style}>
                <Typography
                  sx={{ textAlign: 'center' }}
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Вы уверены?
                </Typography>
                <Button onClick={HandleDetele} variant="contained" color="error">
                  Да!
                </Button>
                <Button sx={{ marginLeft: '52%' }} onClick={handleClose} variant="outlined">
                  Отмена
                </Button>
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default observer(ProfileCard);
