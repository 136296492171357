import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SimpleSlider(props: { props: { props: { children: any[]; }; }; }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
      {props.props.props.children?.map((el:any)=>{
        return el
      })}
      </Slider>
    </div>
  );
}

export default SimpleSlider;