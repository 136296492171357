import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import Blocks from 'pages/Blocks/Blocks';
import Profile from 'pages/Profile';
import { RouterPathEnum } from 'shared';

import LoginPage from './pages/LoginPage';
import { ErrorPage, MainPage, RootLayout } from './pages';

import './App.css';
import 'sanitize.css';


const router = createBrowserRouter([
  {
    path: RouterPathEnum.Main,
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <MainPage />,
      },
    ],
  },
  {
    path: RouterPathEnum.Auth,
    element: <LoginPage />,
  },
  {
    path: RouterPathEnum.None,
    element: <Navigate to={RouterPathEnum.Main} replace />,
  },
  {
    path: RouterPathEnum.Blocks,
    element: <Blocks />,
  },
  {
    path: RouterPathEnum.Profile,
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Profile />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
