import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

/* import ProfilePageStore from 'stores/ProfilePageStore'; */


interface InputProps {
  value: any;
  placeholder: string;
  onChange: (e)=> void;
  defaultValue?: string;
}

function Input({value,placeholder,onChange,defaultValue}:InputProps) {

  /* const setSearch = (e: any) => {
    onChange(e.target.value);
    console.log(e,value)
  }; */
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '50ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
       InputLabelProps={{
        shrink: true,
      }}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
        id="outlined-basic"
        label={placeholder}
        variant="outlined"
        value={value}
        defaultValue={defaultValue}
        onChange={(e: any) => onChange(e.target.value)}
      />
    </Box>
  );
}

export default observer(Input);
