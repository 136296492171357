import { useEffect, useRef, useState } from 'react';
import { TextField } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';
import { Bar, /* BarChart, */ CartesianGrid, ComposedChart, Legend, Tooltip, XAxis, YAxis } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { CustomTooltip2, CustomTooltip3 } from 'shared/helpers/chartFuctions';
import { ScreenSize } from 'shared/helpers/helper';
import { truncate } from 'shared/utils/utils';

import './BarChartVertical.scss';

const cnLineChart = block('BarChartVertical');

const BarChartVertical = ({ data,valueName }: any) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [inputValue, setInputValue] = useState<number | "">(1);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      setWidth(containerRef.current.offsetWidth);
      setHeight(containerRef.current.offsetHeight);
    }
  }, [containerRef, MainPageStore.template]);

  const customizedGroupTick = (props: any) => {
    const {  x, y, payload } = props;

    return (
      <g style={{maxWidth:'425px'}}>
        <text width={425} style={{maxWidth:'425px'}} x={MainPageStore.gisOms ? x-50 :MainPageStore.secondBar ? (x-450) : (x-250)} y={y}>
          {truncate(payload.value, 50)}
        </text>
      </g>
    );
  };

    const scaleChange = (e) => {
      setInputValue(e.target.value);
      console.log(e);
    }
    
  return (
    <div className={cnLineChart()} ref={containerRef}>
      {!MainPageStore.secondBar && ( <TextField
        id="outlined-helperText"
        label={'Масштаб'}
        defaultValue={1}
        value={inputValue}
        size="small"
        color="secondary"
        type='number'
        placeholder='1'
        sx={MainPageStore.gisOms ? {left: '47rem !important'} :{ left:"17rem !important" }}
        onChange={(e: any)=>scaleChange(e)}
      />
      )}
      <ComposedChart
        layout="vertical"
        width={width}
        height={height}
        data={data}
        margin={{
          top: 30,
          right: 20,
          bottom: 10,
          left: 10,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          type="number"
          scale={'linear'}
          domain={[0, dataMax => (dataMax / (inputValue != "" ? inputValue : 1))]}
          
          label={{ value:MainPageStore.gisOms ? 'Число случаев' : 'Сумма (руб.)', offset: -5, position: 'insideBottom' }}
        />
      <YAxis label={{ value:MainPageStore.gisOms ? 'Стоимость, (тыс. руб.)' :undefined, angle: -90, offset: -5, position: 'insideLeft' }} interval={0} width={MainPageStore.gisOms ? 70 : MainPageStore.secondBar ? 450 : 250} tick={customizedGroupTick} hide={ ScreenSize === 'mobile' ? true : false} reversed dataKey="name" type="category" />
        <Tooltip content={MainPageStore.gisOms ? <CustomTooltip3/> : <CustomTooltip2 valueName={valueName} />} />
        <Bar dataKey={valueName ? valueName[0] : "value"} stackId="a" fill={MainPageStore.secondBar ? "red"  : "rgb(131, 109, 90)"} />
        <Legend />
        {MainPageStore.secondBar && (
          <>
          <Bar
            dataKey={valueName ? valueName[0] : "value2"}
            fill="#82ca9d"
            stackId={MainPageStore.ksgPage ? 'a' : undefined} />

            {/* <ReferenceLine  x="1900" strokeWidth={4} stroke="green" /> */}</>
        )}
      </ComposedChart>
    </div>
  );
};

export default observer(BarChartVertical);