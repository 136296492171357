import CropOriginalIcon from '@mui/icons-material/CropOriginal';
/* import GridOnIcon from '@mui/icons-material/GridOn';
import MenuIcon from '@mui/icons-material/Menu'; */
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import MainPageStore from 'stores/MainPageStore';

export default function FlexDivider() {
  const onClickHandler = (templ: string) => {
    MainPageStore.setTemplate(templ);
  };
  return (
    <Box
      sx={{
        'display': 'flex',
        'alignItems': 'center',
        'border': '1px solid',
        'borderColor': 'divider',
        'borderRadius': 2,
        'bgcolor': 'background.paper',
        'color': 'text.secondary',
        '& svg': {
          m: 1,
        },
      }}
      className={"settingsBox"}
    >
      {/* <IconButton children={<GridOnIcon />} onClick={() => onClickHandler('')} />
        <Divider orientation="vertical" variant="middle" flexItem />
      <IconButton children={<MenuIcon />} onClick={() => onClickHandler('oneline')}  />
        <Divider orientation="vertical" variant="middle" flexItem /> */}
      <IconButton children={<ViewComfyIcon />} onClick={() => onClickHandler('mix')} />
        <Divider orientation="vertical" variant="middle" flexItem />
      <IconButton children={<CropOriginalIcon />} onClick={() => onClickHandler('slider')} />
    </Box>
  );
}
