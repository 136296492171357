import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import { Button, } from '@mui/material';
import block from 'bem-cn';
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';
import ProfilePageStore from 'stores/ProfilePageStore';

import { Loader } from 'components';
import Header from 'components/Header/Header';
import Search from 'components/Search/Search';
import TableProfile from 'components/TableProfile/TableProfile';
import { ThemeContext } from 'components/Theme/Theme';
import { RouterPathEnum } from 'shared';

import CreateProfile from './ModalCreateProfile/CreateProfile';
import ProfileCard from './ProfileCard/ProfileCard';

import './Profile.scss';

const cnProfile = block('Profile');

const Profile = () => {
  const { theme }: any = useContext(ThemeContext);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    ProfilePageStore.getUsers();
    if (!MainPageStore.isAdmin) navigate(RouterPathEnum.Main);

  }, [ProfilePageStore.increment]);

  return (
    <>
      <Header />
      <div className={`${cnProfile.toString()} ${theme}`}>
        <Loader visible={ProfilePageStore.isLoading} />
        <div className="Head">Все пользователи <Search/></div>
        
        {ProfilePageStore.users && <TableProfile data={ProfilePageStore.users} />}
        <div className={'btns'}>
          <Button variant="contained" color='success' onClick={()=>setOpen(true)}><AddIcon/> Создать нового пользователя</Button>
        </div>
        <ProfileCard />
        <CreateProfile open={open} setOpen={setOpen} />
      </div>
    </>
  );
};

export default observer(Profile);
