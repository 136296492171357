import { IDepartment, IPageData } from 'shared/interfaces';

import BaseApiService from './BaseApiService';

class MainPageApiService extends BaseApiService {
  constructor() {
    super('api');
  }

  getDashBoards(): Promise<IDepartment> {
    return this.GETBYID('start/');
  }

  postPage(
    path: string,
    dept: string[],
    date1: string,
    date2: string,
    date3?: string,
    date4?: string,
    paytype?: string[],
    bedtype?: string[],
    roomclass?: string[],
    roomtype?: string[],
    building?: string[],
    servgroup?: string[],
    mp_profile_filter?: string[],
    smp_ksg_filter?: string[],
    diagnosis_main_filter?: string[],
    fin_source_filter?: string[],
    mp_name_filter?:string[]
  ): Promise<IPageData> {
    return this.POST(`${path}/`, {
      dept: dept,
      date1: date1,
      date2: date2,
      date3: date3,
      date4: date4,
      paytype: paytype,
      bedtype: bedtype,
      roomclass: roomclass,
      roomtype: roomtype,
      building,
      servgroup: servgroup,
      mp_profile: mp_profile_filter,
      smp_ksg: smp_ksg_filter,
      diagnosis_main: diagnosis_main_filter,
      fin_source: fin_source_filter,
      mp_name:mp_name_filter
    });
  }
}

export default new MainPageApiService();
