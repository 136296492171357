import { IUsers } from 'shared/interfaces/api/ProfilePageInterface';

import BaseApiService from './BaseApiService';

class ProfilePageApiService extends BaseApiService {
  constructor() {
    super('api');
  }

  getUsers(): Promise<IUsers[]> {
    return this.GETBYID(`settings/profiles/`);
  }

  getUSERID(id:number): Promise<IUsers[]> {
    return this.GETBYID(`settings/profiles/${id}/`);
  }

  createUser(username,name,secondname,lastname,email,password,group,tabs): Promise<IUsers[]> {
    return this.POST(`settings/profiles/`,{first_name:name,last_name:lastname,patron_name:secondname,email:email,group:group,tabs:tabs,user:{username: username,password:password,email:email}});
  }

  deleteUser(id:number): Promise<IUsers[]> {
    return this.DELETE(`settings/profiles/${id}/`);
  }

  getGroups(): Promise<IUsers[]> {
    return this.GETBYID(`settings/group/`);
  }

  getTabs(): Promise<IUsers[]> {
    return this.GETBYID(`settings/tab/`);
  }

  patchUser(id:number,family,name,patron,username,password,email,group,tabs): Promise<IUsers> {
    return this.PUT(`settings/profiles/${id}/`,{user:{email:email,username:username,password:password},last_name:family,first_name:name,patron_name:patron,email:email,group:group,tabs:tabs});
  }
}

export default new ProfilePageApiService();
