/* import { useEffect, useState } from 'react'; */
import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import MainSelect from '../components/Select/MainSelect';

interface filtersProps {
  page: string;
}

const Filters = ({ page }: filtersProps) => {
  /* const [filtrs, setFiltrs] = useState<any>([]);

  useEffect(() => {

    const arr = MainPageStore.departmentSelect.map((elem) =>
      MainPageStore.filters.filter((el) => {
        return el[0].includes(elem.value);
      }),
    );
    
    if (page === 'price') {
      setFiltrs(arr.flat());
    } else {
      setFiltrs([]);
    }
  }, [MainPageStore.pageData, MainPageStore.filtersInput, MainPageStore.departmentSelect]); */

  return (
    <>
    {!!MainPageStore.pageData?.mp_name_filter?.length && (
        <MainSelect
          name={'Вид медицинской помощи'}
          page={page}
          options={MainPageStore.pageData?.mp_name_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectMPNameFilter}
          mobxName={'mp_name_filter'}
        />
      )}
    {!!MainPageStore.pageData?.fin_source_filter?.length && (
        <MainSelect
          name={'Источник финансирования'}
          page={page}
          options={MainPageStore.pageData?.fin_source_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectFinSourceFilter}
          mobxName={'fin_source_filter'}
        />
      )}
    {!!MainPageStore.pageData?.mp_profile_filter?.length && (
        <MainSelect
          name={'Профиль МП'}
          page={page}
          options={MainPageStore.pageData?.mp_profile_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectMPPRofileFilter}
          mobxName={'mp_profile_filter'}
        />
      )}
      {!!MainPageStore.pageData?.smp_ksg_filter?.length && (
        <MainSelect
          name={'КСГ ГИС ОМС'}
          page={page}
          options={MainPageStore.pageData?.smp_ksg_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectSMPKSGFilter}
          mobxName={'smp_ksg_filter'}
        />
      )}
      {!!MainPageStore.pageData?.diagnosis_main_filter?.length && (
        <MainSelect
          name={'Основной лиагонз ГИС ОМС'}
          page={page}
          options={MainPageStore.pageData?.diagnosis_main_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectDiagnosisMainFilter}
          mobxName={'diagnosis_main_filter'}
        />
      )}
      {!!MainPageStore.pageData?.paytype_filter?.length && (
        <MainSelect
          name={'Тип оплаты'}
          page={page}
          options={MainPageStore.pageData?.paytype_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectPayType}
          mobxName={'paytypeSelect'}
        />
      )}
      {!!MainPageStore.filters.length && (
        <MainSelect
          name={'Фильтр'}
          page={page}
          options={MainPageStore.filters?.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectFilters}
          mobxName={'filtersInput'}
        />
      )}
      {!!MainPageStore.pageData?.bed_type_filter?.length && (
        <MainSelect
          page={page}
          name={'Тип койки'}
          options={MainPageStore.pageData?.bed_type_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectBedType}
          mobxName={'bedtypeSelect'}
        />
      )}
      {!!MainPageStore.pageData?.room_type_filter?.length && (
        <MainSelect
          page={page}
          name={'Тип палаты'}
          options={MainPageStore.pageData?.room_type_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectRoomClass}
          mobxName={'roomClassTypeSelect'}
        />
      )}
      {!!MainPageStore.pageData?.room_class_filter?.length && (
        <MainSelect
          page={page}
          name={'Класс палаты'}
          options={MainPageStore.pageData?.room_class_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectRoomType}
          mobxName={'roomTypeSelect'}
        />
      )}
      {!!MainPageStore.pageData?.building_filter?.length && (
        <MainSelect
          page={page}
          name={'Корпус'}
          options={MainPageStore.pageData?.building_filter.map((el) => {
            return { label: el[1], value: el[0] };
          })}
          updateFunction={MainPageStore.updateSelectBuildingType}
          mobxName={'buildingTypeSelect'}
        />
      )}
    </>
  );
};

export default observer(Filters);
