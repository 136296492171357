export const formatMonth = (month) => {
    switch(month) {
      case 1:
        return 'Январь';
      case 2:
        return 'Февраль';
      case 3:
        return 'Март';
      case 4:
        return 'Апрель';
      case 5:
        return 'Май';
      case 6:
        return 'Июнь';
      case 7:
        return 'Июль';
      case 8:
        return 'Август';
      case 9:
        return 'Сентябрь';
      case 10:
        return 'Октябрь';
      case 11:
        return 'Ноябрь';
      case 12:
        return 'Декабрь';
      default:
        return '';
    }
  };


export const COLORS = ['#1F7E8F', '#FFD16B', '#831016', '#C93239','#353859','#243E93','#8F6DE3','#60C9DD'];

export const isMobile = window.screen.width < 1280;

export const isPhoneOnly = window.screen.width < 768;

export const ScreenSize = window.screen.width < 768 ? 'mobile' : window.screen.width <= 768 ? 'pad' : window.screen.width < 1440 ? 'desktopMiddle' : 'desktop';

