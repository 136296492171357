import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { observer } from 'mobx-react';

import ProfilePageStore from 'stores/ProfilePageStore';

function Search() {
  const [value, setValue] = React.useState('');

  const setSearch = (e: any) => {
    setValue(e.target.value);
    console.log(e,value)
    setTimeout(() => {
      if (e.target.value == "") {
        return ProfilePageStore.clearUserId();
      } else {
        ProfilePageStore.getUserId(e.target.value);
      }
    }, 500);
  };
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
        id="outlined-basic"
        label="Найти Пользователя"
        variant="outlined"
        value={value}
        type="number"
        onChange={(e: any) => setSearch(e)}
      />
    </Box>
  );
}

export default observer(Search);
