/* import { Typography } from "@mui/material"; */
import { Rectangle } from 'recharts';

import MainPageStore from 'stores/MainPageStore';

import { moneyFormat } from 'shared/utils/utils';

export const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        <div
          style={{
            width: '15px',
            height: '15px',
            marginRight: '10px',
            background: `${payload[0].fill}`,
          }}
        ></div>
        <p className="label">{`${label} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export const CustomTooltip2 = ({ payload, label, valueName }: any) => {

  return (
    <div className="custom-tooltip">
      <div style={MainPageStore.secondBar ? {display:'flex', alignItems:'center', marginRight:'5px', borderRight:'1px solid white', paddingRight:'5px'}:{display:'flex', alignItems:'center'}}>
      <div
        style={{
          width: '15px',
          height: '15px',
          marginRight: '10px',
          background: `${payload[0]?.fill}`,
        }}
      ></div>

      <p className="label">{`${label} : ${MainPageStore.secondBar ? (payload[0] && payload[0].payload[valueName[0]]) : moneyFormat(payload[0]?.value)}`}</p>
      </div>
      {payload[1] && (
        <div style={{display:'flex', alignItems:'center'}}>
          <div
            style={{
              width: '15px',
              height: '15px',
              marginRight: '10px',
              background: `${payload[1]?.fill}`,
            }}
          ></div>
          <p className="label">{`${label} : ${MainPageStore.secondBar ? payload[0]?.payload[valueName[1]].toFixed(2) : moneyFormat(payload[1]?.value)}`}</p>
        </div>
      )}
    </div>
  );
};

export const CustomTooltip3 = ({ payload, label }: any) => {
  return (
    <div className="custom-tooltip">
      <div
        style={{
          width: '15px',
          height: '15px',
          marginRight: '10px',
          background: `${payload[0]?.fill}`,
        }}
      ></div>
      <p className="label">{`y - ${label} : ${payload[0]?.value}`}</p>
    </div>
  );
};

export const CustomShape = (props: any) => {
  return <Rectangle {...props} height={25} x={props.x + 5} y={props.y - 22} />;
};
