import { Typography } from '@mui/material';
import block from 'bem-cn';

import { ReactComponent as Logo} from '../../Icons/SSMU.svg';

import './Footer.scss';

const cnFooter = block('Footer');


const Footer = ({}) => {

  return (
    <div className={cnFooter()} >
      <Logo className='logo' />
      <Typography fontSize={18}>Clinical business intelligence</Typography>
      <Typography fontSize={16}>Мы на связи с вами<br/>belyakov.gy@ssmu.ru</Typography>
    </div>
  );
};



export default Footer;
