import { observer } from 'mobx-react';

import MainPageStore from 'stores/MainPageStore';

import Slider from 'components/Slider/Slider';

import CardWrapper from '../components/CardWrapper/CardWrapper';

const Gis_oms = () => {
  MainPageStore.setGisOmsPage(true);

  return (
    <>
      {MainPageStore.template === 'slider' ? (
        <>
          <Slider
            props={
              <>
                <CardWrapper
                  data={MainPageStore.pageData?.revenue_distribution?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="BarVertical"
                  cardtitle={
                    'Распределение стоимости случая (округлённая вверх до ближайших 10.000), тыс. руб.'
                  }
                />
                <CardWrapper
                  data={MainPageStore.pageData?.number_of_cases?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Area"
                  cardtitle={'Число зарегистрированных случаев, шт.'}
                />
                <CardWrapper
                  data={MainPageStore.pageData?.revenue?.map((el) => {
                    return { value: +el[1], name: el[0] };
                  })}
                  type="Line"
                  cardtitle={'Выручка, тыс. руб.'}
                />
              </>
            }
          />
        </>
      ) : (
        <>
          <CardWrapper
            data={MainPageStore.pageData?.revenue_distribution?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="BarVertical"
            cardtitle={
              'Распределение стоимости случая (округлённая вверх до ближайших 10.000), тыс. руб.'
            }
            
          />
          <CardWrapper
            data={MainPageStore.pageData?.number_of_cases?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Area"
            cardtitle={'Число зарегистрированных случаев, шт.'}
            className={'AreaGis'}
          />
          <CardWrapper
            data={MainPageStore.pageData?.revenue?.map((el) => {
              return { value: +el[1], name: el[0] };
            })}
            type="Line"
            cardtitle={'Выручка, тыс. руб.'}
            className='LineGis'
          />
        </>
      )}
    </>
  );
};

export default observer(Gis_oms);
